import { Form, InputNumber, Typography } from "antd";
import { Rule } from "antd/lib/form";
import { IndividualPool, AggregatePool } from "../types/types";

const InputERC20 = ({
  name,
  rules,
  pool,
  setInput,
}: {
  name: string;
  rules: Rule[] | undefined;
  pool: IndividualPool | AggregatePool;
  setInput: React.Dispatch<React.SetStateAction<string>>;
}): JSX.Element => {
  return (
    <Form.Item name={name} rules={rules}>
      <InputNumber
        addonBefore={<Typography.Text style={{ color: "white" }}>{pool.poolToken.symbol}</Typography.Text>}
        data-lpignore="true"
        controls={false}
        style={{ width: "100%", height: "100%" }}
        onChange={e => {
          if (e) {
            setInput(e.toString());
          }
        }}
        size="large"
      />
    </Form.Item>
  );
};

export default InputERC20;
