import { Button, Card, Col, Row, Table, Typography } from "antd";
import { Link } from "react-router-dom";
import { roundDecimal } from "../helpers/utils";
import { BasePool, ERC20, PoolTypeEnum } from "../types/types";
import AddressLink from "./AddressLink";

const PoolsCard = ({
  pools,
  title,
  link,
}: {
  pools: ReadonlyArray<BasePool>;
  title: string;
  link: string;
}): JSX.Element => {
  const dataSource = pools.map(pool => {
    return {
      key: pool.address,
      ...pool,
    };
  });

  // HACK HACK HACK
  let columns = [
    {
      title: "Currency",
      dataIndex: "poolToken",
      key: "symbol",
      render: (poolToken: ERC20) => (
        <AddressLink address={poolToken.address} text={<Typography.Text>{poolToken.symbol}</Typography.Text>} />
      ),
    },
    {
      title: "Annual Yield",
      dataIndex: "annualYield",
      key: "annualYield",
      render: (annualYield: number) => <span>{roundDecimal(annualYield, 2)}%</span>,
    },
    {
      title: "Undeployed Capital",
      dataIndex: "totalUndeployed",
      key: "totalUndeployed",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (address: string) => <AddressLink address={address} />,
    },
    {
      title: title,
      dataIndex: "address",
      key: title,
      render: (address: string) => {
        const pool = pools.filter((pool: BasePool) => pool.address === address)[0];
        pool.poolToken.contract = null;
        return (
          <span>
            <Link to={`${link}/${address}`} state={{ pool: JSON.stringify(pool) }}>
              <Button type="primary">{title}</Button>
            </Link>
          </span>
        );
      },
    },
  ];

  if (pools.length > 0 && pools[0].type === PoolTypeEnum.Aggregate) {
    columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (name: string) => <span>{name}</span>,
      },
      ...columns,
    ];
  }

  return (
    <Row>
      <Col flex="auto">
        <div className="og-gradient-border">
          <Card>
            <Table columns={columns} dataSource={dataSource} pagination={false} />
          </Card>
        </div>
      </Col>
    </Row>
  );
};

export default PoolsCard;
