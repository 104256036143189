import { BasePool, IndividualPool, AggregatePool } from "../types/types";

export const EMPTY_BASE_POOL: BasePool = {
  address: "0x0",
  type: 0,
  allowance: 0,
  totalUndeployed: 0,
  totalDeployed: 0,
  cumulativeDividends: 0,
  protocolTakeRate: 0,
  poolToken: {
    address: "0x0",
    contract: null,
    decimal: 0,
    symbol: "",
    name: "",
    userBalance: 0,
  },
};

export const EMPTY_INDIVIDUAL_POOL: IndividualPool = {
  ...EMPTY_BASE_POOL,
  recipientAddress: "0x0",
  recipientMaxBalance: 0,
  totalDividends: 0,
  recipientBalance: 0,
  annualYield: 0,
};

export const EMPTY_AGGREGATE_POOL: AggregatePool = {
  ...EMPTY_BASE_POOL,
  poolManagerAddress: "0x0",
  currentIndividualPoolsLength: 0,
  managerTakeRate: 0,
  percentageDecimal: 0,
  individualPoolAllocations: [],
  allocationMarginOfError: 0,
  totalSupply: 0,
  annualYield: 0,
  currentIndividualPools: [],
  currentInvestorInfo: {
    warrantTokenBalance: 0,
    undeployedCapital: 0,
    deployedCapital: 0,
    unclaimedDividends: 0,
    claimedDividends: 0,
  },
};
