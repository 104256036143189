import React from "react";
import { useEthersContext } from "../contexts/ethersContext";
import { shortenAddress } from "../helpers/utils";
import { Network } from "../types/types";

const AddressLink = ({
  address,
  text,
  inputNetwork,
}: {
  address: string;
  text?: JSX.Element;
  inputNetwork?: Network;
}): JSX.Element => {
  const { currentNetwork } = useEthersContext();
  const network = inputNetwork ? inputNetwork : currentNetwork;
  return <a href={`${network.blockExplorer}address/${address}`}>{text ? text : shortenAddress(address)}</a>;
};

export default AddressLink;
