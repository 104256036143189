import { Button, Typography } from "antd";
import { ethers } from "ethers";

const Faucet = ({
  localProvider,
  faucetTx,
  address,
}: {
  localProvider: ethers.providers.StaticJsonRpcProvider;
  // eslint-disable-next-line
  faucetTx: any;
  address: string;
}): JSX.Element => {
  if (localProvider && localProvider._network && localProvider._network.chainId === 31337) {
    return (
      <Button
        type="primary"
        onClick={() => {
          faucetTx({
            to: address,
            value: ethers.utils.parseEther("1"),
          });
        }}
      >
        <Typography.Text>💰 Faucet ⛽️</Typography.Text>
      </Button>
    );
  }
  return <></>;
};

export default Faucet;
