// This file refers to the gitignored hardhat_contracts file
const hardhatContractsPromise = import("../contracts/hardhat_contracts.json");

// This file refers to the non-gitignroed version that is used in front-end deployments on Netlify
const deployedContractsPromise = import("../contracts/deployed_contracts.json");

// These are any contracts that we hard-code from non-local chains (such as ERC20)
const externalContractsPromise = import("../contracts/external_contracts");

export const loadAppContracts = async (): Promise<{ deployedContracts: unknown; externalContracts: unknown }> => {
  const config = { deployedContracts: {}, externalContracts: {} };

  const hardhatContracts = (await hardhatContractsPromise).default || {};
  const deployedContracts = (await deployedContractsPromise).default || {};

  config.deployedContracts = {
    ...hardhatContracts,
    ...deployedContracts,
  };
  config.externalContracts = (await externalContractsPromise).default ?? {};
  return config;
};
