import { Button, Typography } from "antd";
import { Signer } from "ethers";

const AuthStatusButton = ({ userSigner }: { userSigner: Signer | undefined }): JSX.Element => {
  return (
    <Button style={{ backgroundColor: "#251818" }} block={true}>
      {!!userSigner ? (
        <Typography.Text style={{ color: "#16DE36" }}>Connected</Typography.Text>
      ) : (
        <Typography.Text style={{ color: "#DE2016" }}>Disconnected</Typography.Text>
      )}
    </Button>
  );
};

export default AuthStatusButton;
