// @ts-nocheck
import { Contract, ethers } from "ethers";
import {
  AggregatePool,
  BasePool,
  CurrentInvestorInfo,
  ERC20,
  IndividualPool,
  IndividualPoolTableInfo,
  Network,
} from "../types/types";
import { ERC20ABI } from "../contracts/external_contracts";

import { isAggregatePoolContract, isBasePoolContract, isConfigContract, isIndividualPoolContract } from "./typeGuards";
import { PrecisionConverter } from "./PrecisionConverter";
import { findIndividualPoolContract } from "./utils";
import { PartialAggregatePool, PartialIndividualPool, POOL_INFO } from "../Constants";

export const buildERC20Contract = (
  tokenAddress: string,
  rpcUrl: string,
  userSigner: ethers.Signer | undefined,
  isCorrectNetwork: boolean,
) => {
  if (userSigner !== undefined && isCorrectNetwork) {
    return new ethers.Contract(tokenAddress, ERC20ABI, userSigner);
  }
  const localProvider = new ethers.providers.StaticJsonRpcProvider(rpcUrl);
  return new ethers.Contract(tokenAddress, ERC20ABI, localProvider);
};

type Unresolved<Type> = {
  [property in keyof Type]: Promise<Type[property]>;
};

const _buildBasePool = async (
  userSigner: ethers.Signer | undefined,
  poolContract: Contract,
  configContract: Contract,
  rpcUrl: string,
  cachedPool: Unresolved<PartialAggregatePool> | Unresolved<PartialIndividualPool>,
  isCorrectNetwork: boolean,
): Promise<BasePool> => {
  if (!isBasePoolContract(poolContract)) {
    throw new Error("Contract passed in is not a base pool contract");
  }
  if (!isConfigContract(configContract)) {
    throw new Error("Contract passed in is not a protocol config contract");
  }

  const poolTokenContract = buildERC20Contract(await poolContract.poolToken(), rpcUrl, userSigner, isCorrectNetwork);

  const currentAddress = await userSigner?.getAddress();

  const poolTokenDecimals = await poolTokenContract.decimals();

  const precisionConverter = new PrecisionConverter(poolTokenDecimals);

  const [
    totalDeployed,
    cumulativeDividends,
    totalUndeployed,
    type,
    protocolTakeRate,
    poolTokenAddress,
    poolTokenSymbol,
    poolTokenName,
  ] = [
    poolContract.getTotalDeployedAmount().then(result => precisionConverter.fromContractAmount(result)),
    poolContract.getCumulativeDividends().then(result => precisionConverter.fromContractAmount(result)),
    poolContract.getTotalUndeployedAmount().then(result => precisionConverter.fromContractAmount(result)),
    poolContract.poolType(),
    Promise.all([
      configContract.getProtocolTakeRate().then(result => parseInt(result.toString())),
      configContract.TAKE_RATE_PRECISION().then(result => parseInt(result.toString())),
    ]).then(([takeRate, takeRatePrecision]) => takeRate / takeRatePrecision),
    poolContract.poolToken(),
    poolTokenContract.symbol(),
    poolTokenContract.name(),
  ];

  const { poolToken: cachedPoolToken, ...cachedPoolWithoutToken } = cachedPool;

  const poolToken: ERC20 = await _resolveTheUnresolved({
    address: poolTokenAddress,
    contract: Promise.resolve(poolTokenContract),
    decimal: Promise.resolve(poolTokenDecimals),
    symbol: poolTokenSymbol,
    name: poolTokenName,
    userBalance: !currentAddress
      ? Promise.resolve(0)
      : poolTokenContract.balanceOf(currentAddress).then(result => precisionConverter.fromContractAmount(result)),
    // eslint-disable-next-line
    ...(cachedPoolToken as any),
  });

  const basePoolWithoutToken: Omit<BasePool, "poolToken"> = await _resolveTheUnresolved({
    address: Promise.resolve(poolContract.address),
    type,
    allowance: !currentAddress
      ? Promise.resolve(0)
      : poolTokenContract
          .allowance(currentAddress, poolContract.address)
          .then(result => precisionConverter.fromContractAmount(result)),
    totalUndeployed,
    totalDeployed,
    cumulativeDividends,
    protocolTakeRate,
    // eslint-disable-next-line
    ...(cachedPoolWithoutToken as any),
  });

  return {
    poolToken,
    ...basePoolWithoutToken,
  };
};

export const buildIndividualPool = async (
  userSigner: ethers.Signer | undefined,
  poolContract: Contract,
  configContract: Contract,
  network: Network,
  isCorrectNetwork: boolean,
): Promise<IndividualPool> => {
  if (!isIndividualPoolContract(poolContract)) {
    throw new Error("Contract passed in is not a individual pool contract");
  }
  if (!isConfigContract(configContract)) {
    throw new Error("Contract passed in is not a protocol config contract");
  }

  const cachedIndividualPool = await _getCachedPoolInfo(poolContract, network.key);

  const basePool = await _buildBasePool(
    userSigner,
    poolContract,
    configContract,
    network.rpcUrl,
    cachedIndividualPool,
    isCorrectNetwork,
  );

  const precisionConverter = new PrecisionConverter(basePool.poolToken.decimal);

  const [recipientMaxBalance, totalDividends, recipientBalance, firstWithdrawalTime] = await Promise.all([
    poolContract.recipientMaxBalance().then(result => precisionConverter.fromContractAmount(result)),
    poolContract.getCumulativeDividends().then(result => precisionConverter.fromContractAmount(result)),
    poolContract.getWithdrawableBalance().then(result => precisionConverter.fromContractAmount(result)),
    poolContract.getFirstWithdrawalTime().then(result => result.toNumber()),
  ]);
  const annualYield = _calculateAnnualYield(firstWithdrawalTime, totalDividends, basePool.totalDeployed);
  return {
    ...basePool,
    recipientAddress: await poolContract.getRecipient(),
    recipientMaxBalance,
    totalDividends,
    recipientBalance,
    annualYield,
  };
};

export const buildAggregatePool = async (
  userSigner: ethers.Signer | undefined,
  poolContract: Contract,
  readContracts: Record<string, Contract>,
  network: Network,
  isCorrectNetwork: boolean,
): Promise<AggregatePool> => {
  if (!isAggregatePoolContract(poolContract)) {
    throw new Error("Contract passed in is not a individual pool contract");
  }
  const configContract = readContracts["ProtocolConfig"];
  if (!isConfigContract(configContract)) {
    throw new Error("Contract passed in is not a protocol config contract");
  }

  const cachedAggregatePool = await _getCachedPoolInfo(poolContract, network.key);
  const basePool = await _buildBasePool(
    userSigner,
    poolContract,
    configContract,
    network.rpcUrl,
    cachedAggregatePool,
    isCorrectNetwork,
  );
  const precisionConverter = new PrecisionConverter(basePool.poolToken.decimal);

  const currentAddress = await userSigner?.getAddress();

  const [
    currentInvestorInfo,
    poolManagerAddress,
    poolManagerTakeRate,
    takeRatePrecision,
    totalSupply,
    allocationMarginOfError,
    percentageDecimal,
    currentIndividualPoolAddresses,
  ] = await Promise.all([
    _getCurrentInvestorInfo(poolContract, precisionConverter, currentAddress),
    poolContract.getPoolManager(),
    configContract.POOL_MANAGER_TAKE_RATE().then(result => parseInt(result.toString())),
    configContract.TAKE_RATE_PRECISION().then(result => parseInt(result.toString())),
    poolContract.totalSupply().then(result => precisionConverter.fromContractAmount(result)),
    poolContract.ALLOCATION_MARGIN_OF_ERROR().then(result => precisionConverter.fromContractAmount(result)),
    poolContract.PERCENTAGE_DECIMAL().then(result => precisionConverter.fromContractAmount(result)),
    poolContract.getCurrentIndividualPools(),
  ]);

  const allocationRange = [...Array(currentIndividualPoolAddresses.length).keys()];

  const individualPoolAllocations = await Promise.all(
    allocationRange.map(async index => {
      const address = await poolContract.currentIndividualPools(index);
      const allocation = await poolContract.individualPoolAllocations(address);
      return { address, allocation: precisionConverter.fromContractAmount(allocation) / percentageDecimal };
    }),
  );

  const currentIndividualPoolContracts = currentIndividualPoolAddresses
    .map(poolAddress => findIndividualPoolContract(poolAddress, readContracts))
    .filter(individualPoolContract => !!individualPoolContract);

  const currentIndividualPools: IndividualPoolTableInfo[] = (
    await Promise.all(
      currentIndividualPoolContracts.map(individualPoolContract =>
        Promise.all([
          Promise.resolve(individualPoolContract.address),
          individualPoolContract.getFirstWithdrawalTime().then(result => result.toNumber()),
          individualPoolContract
            .totalUnclaimedDividends()
            .then(result => precisionConverter.fromContractAmount(result)),
          individualPoolContract.getCumulativeDividends().then(result => precisionConverter.fromContractAmount(result)),
          individualPoolContract.getTotalDeployedAmount().then(result => precisionConverter.fromContractAmount(result)),
          individualPoolContract.getRecipient(),
          individualPoolAllocations.filter(pool => pool.address === individualPoolContract.address)[0].allocation,
        ]),
      ),
    )
  ).map(
    ([
      address,
      firstWithdrawalTime,
      unclaimedDividends,
      cumulativeDividends,
      deployedCapital,
      recipientAddress,
      allocation,
    ]) => {
      const annualYield = _calculateAnnualYield(firstWithdrawalTime, cumulativeDividends, deployedCapital);
      return {
        address,
        firstWithdrawalTime,
        unclaimedDividends,
        annualYield,
        recipientAddress,
        allocation,
      };
    },
  );
  const firstWithdrawalTime = currentIndividualPools
    .map(pool => pool.firstWithdrawalTime)
    .filter(withdrawalTime => withdrawalTime != 0)
    .sort((a, b) => a - b)[0];

  const annualYield = !firstWithdrawalTime
    ? 0
    : _calculateAnnualYield(firstWithdrawalTime, basePool.cumulativeDividends, basePool.totalDeployed);

  return {
    ...basePool,
    poolManagerAddress,
    currentIndividualPoolsLength: currentIndividualPools.length,
    managerTakeRate: poolManagerTakeRate / takeRatePrecision,
    percentageDecimal,
    individualPoolAllocations,
    allocationMarginOfError,
    totalSupply,
    annualYield,
    currentIndividualPools,
    currentInvestorInfo,
  };
};

const _calculateAnnualYield = (firstWithdrawalTime: number, cumulativeDividends: number, deployedCapital: number) => {
  const now = Date.now() / 1000;
  const secondsInDay = 86400;
  const daysPassed = (now - firstWithdrawalTime) / secondsInDay;
  const annualYield = !deployedCapital ? 0 : ((cumulativeDividends * (365 / daysPassed)) / deployedCapital) * 100;
  return annualYield;
};

const _getCurrentInvestorInfo = async (
  poolContract,
  precisionConverter: PrecisionConverter,
  currentAddress: string | undefined,
): Promise<CurrentInvestorInfo> => {
  if (!currentAddress) {
    return {
      warrantTokenBalance: 0,
      undeployedCapital: 0,
      deployedCapital: 0,
      unclaimedDividends: 0,
      claimedDividends: 0,
    };
  }
  const [warrantTokenBalance, undeployedCapital, deployedCapital, unclaimedDividends, claimedDividends] =
    await Promise.all([
      poolContract.balanceOf(currentAddress).then(result => precisionConverter.fromContractAmount(result)),
      poolContract
        .getInvestorUndeployedAmount(currentAddress)
        .then(result => precisionConverter.fromContractAmount(result)),
      poolContract
        .getInvestorDeployedAmount(currentAddress)
        .then(result => precisionConverter.fromContractAmount(result)),
      poolContract
        .getInvestorUnclaimedDividends(currentAddress)
        .then(result => precisionConverter.fromContractAmount(result)),
      poolContract
        .getInvestorClaimedDividends(currentAddress)
        .then(result => precisionConverter.fromContractAmount(result)),
    ]);

  return {
    warrantTokenBalance,
    undeployedCapital,
    deployedCapital,
    unclaimedDividends,
    claimedDividends,
  };
};

// Resolves all top-level promises in an object
const _resolveTheUnresolved = async <T>(unresolvedObject: Unresolved<T>): Promise<T> => {
  const keys = Object.keys(unresolvedObject);
  const values = await Promise.all(Object.values(unresolvedObject));

  const resolvedObject: T = keys.reduce(
    (others, key, index) => ({
      ...others,
      [key]: values[index],
    }),
    {} as T,
  );
  return resolvedObject;
};

// Given a user signer (who's logged onto a given network) and a poolContract (with a given poolAddress), return
// an object that wraps all the poolAddress's cached values in promises. If userSigner is undefined, return the
// cached values for the target network.
const _getCachedPoolInfo = async (
  poolContract: Contract,
  networkKey: string,
): Promise<Unresolved<PartialAggregatePool> | Unresolved<PartialIndividualPool>> => {
  const individualPoolAddress = poolContract.address;

  const allPools = {
    ...POOL_INFO[networkKey as keyof typeof POOL_INFO]["aggregatePools"],
    ...POOL_INFO[networkKey as keyof typeof POOL_INFO]["individualPools"],
  };

  const cachedPool = allPools[individualPoolAddress.toLowerCase() as keyof typeof allPools];

  if (cachedPool) {
    return _transformObjectValues(cachedPool, (_key, value) => Promise.resolve(value));
  }
  return {};
};

// Returns a new object that applies the transformation function to the input object
const _transformObjectValues = (
  object: Record<string, unknown>,
  transform: (key: unknown, value: unknown, index: number) => unknown,
) => {
  return Object.fromEntries(Object.entries(object).map(([key, value], index) => [key, transform(key, value, index)]));
};
