import { Button } from "antd";
import React from "react";
import { NETWORK } from "../Constants";
import { useEthersContext } from "../contexts/ethersContext";

type NetworkDisplayProps = {
  clientChainId: number;
};

const NetworkDisplay = ({ clientChainId }: NetworkDisplayProps): JSX.Element => {
  const { currentNetwork } = useEthersContext();
  const networkLocal = NETWORK(clientChainId);
  return (
    <a
      onClick={async () => {
        const ethereum = window.ethereum;
        const data = [
          {
            chainId: "0x" + currentNetwork.chainId.toString(16),
            chainName: currentNetwork.name,
            rpcUrls: [currentNetwork.rpcUrl],
            blockExplorerUrls: [currentNetwork.blockExplorer],
          },
        ];

        // https://docs.metamask.io/guide/rpc-api.html#other-rpc-methods
        try {
          await ethereum?.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: data[0].chainId }],
          });
        } catch (switchError) {
          // not checking specific error code, because maybe we're not using MetaMask
          try {
            await ethereum?.request({
              method: "wallet_addEthereumChain",
              params: data,
            });
          } catch (addError) {
            // handle "add" error
          }
        }
      }}
    >
      <Button size="large">{`Switch to ${networkLocal && networkLocal.name}`}</Button>
    </a>
  );
};

export default NetworkDisplay;
