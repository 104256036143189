import { Button, Modal, Space, Typography } from "@openguild/design-system";
import { Spin } from "antd";
import { useState } from "react";
import { useConnect } from "wagmi";
import { showError } from "../helpers/toasts";

const AuthModal = () => {
  const { connect, connectors, error, isLoading, pendingConnector } = useConnect();

  const [visible, setVisible] = useState(false);

  if (error) {
    showError(`Failed to authenticate: ${error.message}`);
  }

  return (
    <>
      <Button size="large" onClick={() => setVisible(true)}>
        Connect Wallet
      </Button>
      <Modal visible={visible} onCancel={() => setVisible(false)} footer={null}>
        <Space direction="vertical" size="middle" style={{ display: "flex", paddingTop: 30 }}>
          <Typography.Title style={{ textAlign: "center" }} level={5}>
            Connect Wallet
          </Typography.Title>
          {connectors.map(connector => (
            <Button
              size="large"
              style={{ width: "100%" }}
              disabled={!connector.ready}
              key={connector.id}
              onClick={() => {
                connect({ connector });
                setVisible(false);
              }}
            >
              {connector.name}
              {!connector.ready && " (unsupported)"}
              {isLoading && connector.id === pendingConnector?.id && <Spin size="small" style={{ paddingLeft: 10 }} />}
            </Button>
          ))}
        </Space>
      </Modal>
    </>
  );
};

export default AuthModal;
