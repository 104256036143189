import { Card, Row, Typography } from "antd";
import { roundDecimal } from "../helpers/utils";

const CapitalCard = ({
  undeployedCapital,
  deployedCapital,
  symbol,
}: {
  undeployedCapital: number;
  deployedCapital: number;
  symbol: string;
}): JSX.Element => {
  return (
    <Card size="small" style={{ backgroundColor: "#150428" }}>
      <Row style={{ paddingTop: "1vh" }}>
        <Typography.Text style={{ fontSize: "15px" }}>Undeployed Capital</Typography.Text>
      </Row>
      <Row style={{ paddingTop: "0.5vh" }}>
        <Typography.Text style={{ fontSize: "15px" }}>
          {roundDecimal(undeployedCapital, 3)} {symbol}
        </Typography.Text>
      </Row>
      <Row style={{ paddingTop: "1vh" }}>
        <Typography.Text style={{ fontSize: "15px" }}>Deployed Capital</Typography.Text>
      </Row>
      <Row style={{ paddingTop: "0.5vh" }}>
        <Typography.Text style={{ fontSize: "15px" }}>
          {roundDecimal(deployedCapital, 3)} {symbol}
        </Typography.Text>
      </Row>
    </Card>
  );
};

export default CapitalCard;
