import { Col, Layout, Row, Spin, Typography } from "antd";
import { useContext, useEffect, useState } from "react";
import { useEthersContext } from "../contexts/ethersContext";
import { isBasePoolContract } from "../helpers/typeGuards";
import { buildAndSetAggregatePool, findPool, shortenAddress } from "../helpers/utils";
import { AggregatePool } from "../types/types";
import PoolManagerForm from "./PoolManagerForm";
import AddressLink from "../components/AddressLink";
import { Contract } from "ethers";
import { EMPTY_AGGREGATE_POOL } from "../helpers/emptyPools";
import { StoreContext } from "../providers/store";
import { useParams } from "react-router-dom";

type ManagePoolHomeProps = {
  poolAddress: string;
};

const ManagePoolHome = (): JSX.Element => {
  const { poolAddress } = useParams() as ManagePoolHomeProps;
  const { userSigner, writeContracts, readContracts, currentNetwork, isCorrectNetwork } = useEthersContext();
  const { aggregatePools, updateAggregatePool } = useContext(StoreContext);

  const [isPoolManager, setIsPoolManager] = useState<boolean>();
  const [loading, setLoading] = useState(true);

  const [poolContract, setPoolContract] = useState<Contract | undefined>(
    findPool(poolAddress, readContracts, isBasePoolContract),
  );
  const [aggregatePool, setAggregatePool] = useState<AggregatePool>(EMPTY_AGGREGATE_POOL);

  useEffect(() => {
    (async function () {
      const allContracts = !userSigner || isCorrectNetwork === false ? readContracts : writeContracts;
      // we are guaranteed to have contracts be of length at least 0, so continue to keep loading state
      if (Object.keys(allContracts).length === 0) {
        return;
      }

      const currentAddress = !userSigner ? "0x0" : await userSigner.getAddress();
      if (typeof aggregatePools[poolAddress] !== "undefined" && currentAddress === "0x0") {
        setAggregatePool(aggregatePools[poolAddress]);
        setLoading(false);
      } else {
        const newPoolContract = findPool(poolAddress, allContracts, isBasePoolContract);
        setPoolContract(newPoolContract);
        if (newPoolContract) {
          buildAndSetAggregatePool(
            userSigner,
            newPoolContract,
            allContracts,
            currentNetwork,
            pool => {
              updateAggregatePool(pool);
              setAggregatePool(pool);
              setLoading(false);
            },
            isCorrectNetwork,
          );
        } else {
          setLoading(false);
        }
      }
    })();
  }, [userSigner, readContracts, writeContracts]);

  useEffect(() => {
    (async () => {
      setIsPoolManager((await userSigner?.getAddress()) === aggregatePool.poolManagerAddress);
    })();
  }, [userSigner]);

  if (isCorrectNetwork === false || isPoolManager === false) {
    return <></>;
  }

  if (loading) {
    return <Spin />;
  }

  return (
    <Layout style={{ padding: "2em 2em" }}>
      <Row>
        <AddressLink
          address={aggregatePool.address}
          text={
            <Typography.Title level={4}>{`AggregatePool: ${shortenAddress(aggregatePool.address)}`}</Typography.Title>
          }
        />
      </Row>
      <Row style={{ marginTop: "10px", alignItems: "center" }}>
        <Col span={10} offset={4}>
          <PoolManagerForm pool={aggregatePool} poolContract={poolContract} />
        </Col>
      </Row>
    </Layout>
  );
};

export default ManagePoolHome;
