import { Contract } from "ethers";

export const isBasePoolContract = (contract: Contract) => {
  return typeof contract?.poolType !== "undefined" && typeof contract?.poolToken !== "undefined";
};

export const isIndividualPoolContract = (contract: Contract) => {
  return typeof contract?.claimFromAggregatePool !== "undefined" && isBasePoolContract(contract);
};

export const isAggregatePoolContract = (contract: Contract) => {
  return typeof contract?.setPoolAllocations !== "undefined" && isBasePoolContract(contract);
};

export const isConfigContract = (contract: Contract) => {
  return typeof contract?.getTreasuryAddress !== "undefined";
};

export const isERC20Contract = (contract: Contract) => {
  return (
    typeof contract?.decimals !== "undefined" &&
    typeof contract?.balanceOf !== "undefined" &&
    typeof contract?.transfer !== "undefined"
  );
};
