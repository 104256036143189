import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, Modal, Row } from "antd";
import { Contract } from "ethers";
import React, { useState } from "react";
import { PrecisionConverter } from "../helpers/PrecisionConverter";
import { AggregatePool } from "../types/types";
import { useEthersContext } from "../contexts/ethersContext";
import * as Toast from "../helpers/toasts";
import { buildAndSetAggregatePool, compareAddresses } from "../helpers/utils";
import ApproveButton from "./ApproveButton";
import InputERC20 from "./InputERC20";
import { erc20ABI, useContractEvent } from "wagmi";
const { confirm } = Modal;

type InvestorFormProps = {
  poolContract: Contract | undefined;
  pool: AggregatePool;
  setPool: (aggregatePool: AggregatePool) => void;
};

export const InvestForm = ({ poolContract, pool, setPool }: InvestorFormProps): JSX.Element => {
  const { writeContracts, tx, currentNetwork, userSigner, isCorrectNetwork } = useEthersContext();
  const [form] = Form.useForm();
  const [investInput, setInvestInput] = useState<string>("0");
  const precisionConverter = new PrecisionConverter(pool.poolToken.decimal);

  const [allowance, setAllowance] = useState(0);

  useContractEvent({
    addressOrName: pool.poolToken.address,
    contractInterface: erc20ABI,
    eventName: "Approval",
    listener: ([, spender, amount]) => {
      // There's a possibility that multiple approvals are in the same block (see https://github.com/ethers-io/ethers.js/issues/2310),
      // so we need to check that we only set allowance for approvals where spender is the pool contract
      if (compareAddresses(spender, pool.address)) {
        setAllowance(precisionConverter.fromContractAmount(amount));
      }
    },
  });

  const investConfirmation = () => {
    const message = (
      <>
        By clicking OK, you are agreeing to invest {investInput} {pool.poolToken.symbol}. <br />
        <br />
        You also attest that you have read and understand the risks associated with this transaction, including, but not
        limited to, the risks outlined in our{" "}
        <a
          href="https://docs.openguild.finance/v1/faqs/general-faqs/investor-faqs"
          style={{ textDecoration: "underline" }}
        >
          documentation.
        </a>
        <br />
        <br />
        Finally, you confirm that you are not initiating this transaction from any of the following restricted
        countries: <br />
        <br />
        Afghanistan, Ivory Coast, Cuba, Iraq, Iran, Liberia, North Korea, Syria, Sudan, South Sudan, Zimbabwe, Antigua,
        United States, American Samoa, Guam, Northern Mariana Islands, Puerto Rico, United States Minor Outlying
        Islands, US Virgin Islands, Ukraine, Belarus, Albania, Burma, Central African Republic, Democratic Republic of
        Congo, Lybia, Somalia, Yemen, United Kingdom, Thailand, Australia.{" "}
        {pool.managerTakeRate > 0
          ? `${pool.managerTakeRate * 100}% of the dividends will be sent to the pool manager.`
          : ""}
      </>
    );
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: message,
      onOk: async () => {
        if (poolContract && userSigner) {
          const currentUserAddress = await userSigner.getAddress();
          if (
            parseFloat(investInput) >
            precisionConverter.fromContractAmount(await pool.poolToken.contract?.balanceOf(currentUserAddress))
          ) {
            Toast.balanceFailureNotification(pool.poolToken.symbol);
          } else {
            // eslint-disable-next-line
            await tx(poolContract.invest(precisionConverter.toContractAmount(investInput)), (update: any) => {
              if (update && (update.status === "confirmed" || update.status === 1)) {
                buildAndSetAggregatePool(
                  userSigner,
                  poolContract,
                  writeContracts,
                  currentNetwork,
                  setPool,
                  isCorrectNetwork,
                ).then(() => {
                  Toast.investSuccessNotification(investInput, pool);
                });
              }
            });
          }
        } else {
          Toast.approveSuccessNotification(pool.poolToken.symbol);
        }
      },
    });
  };
  return (
    <Form form={form} layout="vertical" onFinish={investConfirmation}>
      <Row>
        <Col span={24}>
          <InputERC20
            name="investmentAmount"
            rules={[
              { type: "number", message: "Enter a valid number" },
              { required: true, message: "" },
              { min: 0.0000000000000000001, message: "Must be more than 0", type: "number" },
            ]}
            pool={pool}
            setInput={setInvestInput}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {allowance === 0 ? (
            <ApproveButton pool={pool} poolContract={poolContract} setPool={setPool} />
          ) : (
            <Form.Item shouldUpdate>
              {() => (
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={
                    !tx ||
                    !form.isFieldsTouched(true) ||
                    isCorrectNetwork === false ||
                    !!form.getFieldsError().filter(({ errors }) => errors.length).length
                  }
                  size="large"
                  block={true}
                  style={{ height: "100%" }}
                >
                  Invest
                </Button>
              )}
            </Form.Item>
          )}
        </Col>
      </Row>
    </Form>
  );
};

export default InvestForm;
