import { Button } from "antd";

const HeaderMenuButton = ({ text, onClick }: { text: string; onClick: () => void }): JSX.Element => {
  return (
    <Button type="text" style={{ color: "white" }} onClick={onClick}>
      {text}
    </Button>
  );
};

export default HeaderMenuButton;
