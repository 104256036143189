import { BigNumber, Contract } from "ethers";
import { useAccount, useBalance, useContractRead } from "wagmi";

export type ERC20 = {
  address: string;
  contract: Contract | null;
  decimal: number;
  symbol: string;
  name: string;
  userBalance: number;
};

export type CurrentInvestorInfo = {
  warrantTokenBalance: number;
  undeployedCapital: number;
  deployedCapital: number;
  unclaimedDividends: number;
  claimedDividends: number;
};

export enum PoolTypeEnum {
  Aggregate = 0,
  Individual = 1,
  Invalid = 2,
}

export enum TeamBuyStatusEnum {
  ONGOING = 0,
  UNSUCCESSFULLY_TERMINATED = 1,
  SUCCESSFULLY_TERMINATED = 2,
}

export type LocationState = {
  pool: string;
};

export type Network = {
  blockExplorer: string;
  key: string;
  chainId: number;
  color: string;
  faucet?: string;
  gasPrice?: number;
  name: string;
  price?: number;
  rpcUrl: string;
};

export type BasePool = {
  address: string;
  type: PoolTypeEnum;
  allowance: number;
  totalUndeployed: number;
  totalDeployed: number;
  cumulativeDividends: number;
  protocolTakeRate: number;
  poolToken: ERC20;
};

export type IndividualPool = BasePool & {
  recipientAddress: string;
  recipientMaxBalance: number;
  totalDividends: number;
  recipientBalance: number;
  annualYield: number;
};

export type IndividualPoolTableInfo = {
  address: string;
  firstWithdrawalTime: number;
  unclaimedDividends: number;
  annualYield: number;
  recipientAddress: string;
};

export type AggregatePool = BasePool & {
  poolManagerAddress: string;
  currentIndividualPoolsLength: number;
  managerTakeRate: number;
  percentageDecimal: number;
  individualPoolAllocations: { address: string; allocation: number }[];
  allocationMarginOfError: number;
  totalSupply: number;
  annualYield: number;
  currentIndividualPools: IndividualPoolTableInfo[];
  currentInvestorInfo: CurrentInvestorInfo;
};

export enum ActionTab {
  Deposit = 1,
  Claim = 2,
  Withdraw = 3,
  Contribute = 4,
}

export enum InfoTab {
  PoolStats = 1,
  InvestorInfo = 2,
  IndividualPools = 3,
  RecipientInfo = 4,
}

export type WithdrawalResult = {
  poolAddress: string;
  withdrawnAt: number;
  timeSinceWithdrawal: number;
  undeployedAmount: number;
};

export type ManagementFeeResult = {
  poolAddress: string;
  recipientAddress: string;
  recipientSent: number;
  deployedAmount: number;
};

export type RatioResult = {
  poolAddress: string;
  recipientAddress: string;
  totalContributed: number;
  deployedAmount: number;
  sentToEscrow: number;
  sentToPersonal: number;
};

export type ERC20Token = {
  tokenAddress: string;
  name: string;
  symbol: string;
  balance: BigNumber;
  decimals: number;
  network: string;
  usdPrice: number;
};

export type ERC721Token = {
  tokenAddress: string;
  tokenId: string;
  contractType: string;
  name: string;
  network: string;
};

export type ERC1155Token = {
  tokenAddress: string;
  tokenId: string;
  contractType: string;
  name: string;
  network: string;
};

export type SnapshotResult = {
  poolAddress: string;
  recipientAddress: string;
  ERC20Tokens: ERC20Token[];
  ERC721Tokens: ERC721Token[];
  ERC1155Tokens: ERC1155Token[];
};

export type ERC20TokensResult = {
  poolAddress: string;
  recipientAddress: number;
  ERC20Tokens: ERC20Token[];
};

export type NFTResult = {
  poolAddress: string;
  recipientAddress: number;
  ERC721Tokens: ERC721Token[];
  ERC1155Tokens: ERC1155Token[];
};

export type ERC20TableData = {
  name: string;
  balance: number;
  symbol: string;
  tokenAddress: string;
  network: string;
};

export type NFTTableData = {
  name: string;
  tokenId: number;
  tokenAddress: string;
  contractType: string;
  network: string;
};

export type NFTTransactionTableData = {
  name: string;
  tokenAddress: string;
  transactionHash: string;
  contractType: string;
  fromAddress: string;
  toAddress: string;
  network: string;
};

export type ERC20Transaction = {
  name: string;
  symbol: string;
  decimals: number;
  transactionHash: string;
  address: string;
  blockTimestamp: string;
  blockNumber: number;
  blockHash: string;
  toAddress: string;
  fromAddress: string;
  value: BigNumber;
  usdPrice: number;
  network: string;
};

export type ERC20TransactionTableData = {
  symbol: string;
  value: string;
  transactionHash: string;
  toAddress: string;
  fromAddress: string;
  network: string;
};

export type ERC20TransactionsResult = {
  poolAddress: string;
  recipientAddress: string;
  ERC20Transactions: ERC20Transaction[];
};

export type ERC721Transaction = {
  name: string;
  symbol: string;
  blockNumber: number;
  blockTimestamp: string;
  blockHash: string;
  transactionHash: string;
  transactionIndex: number;
  logIndex: number;
  value: BigNumber;
  contractType: string;
  transactionType: string;
  tokenAddress: string;
  tokenId: string;
  fromAddress: string;
  toAddress: string;
  amount: number;
  verified: number;
  operator: string;
  network: string;
};

export type ERC721TransactionsResult = {
  poolAddress: string;
  recipientAddress: number;
  ERC721Transactions: ERC721Transaction[];
};

export type AuditResult = {
  WithdrawalResult: WithdrawalResult[];
  ManagementFeeResult: ManagementFeeResult[];
  RatioResult: RatioResult[];
  SnapshotResult: SnapshotResult[];
};

export type AggregatePoolInfo = {
  poolAddress: string;
  poolTokenAddress: string;
  symbol: string;
  decimals: number;
};

export type FilterResult = {
  text: string;
  value: string;
};

export type TeamBuyDealData = {
  game: TeamBuyGameData;
  contract: {
    address: string;
    chainId: number;
  };
  collection: TeamBuyDealDataCollection;
  seller: string;
  maxAssetsPerAddress: number;
  minAssetsPerAddress: number;
  totalMaxAssets: number;
  totalMinAssets: number;
  PERCENTAGE_DECIMALS: number;
  takeRateFromBuyer: number;
  takeRateFromGame: number;
  token: {
    address: string;
    decimal: number;
    symbol: string;
    name: string;
  };
  mintPrice: number;
  discountTiers: {
    [quantity: number]: {
      price: number;
      nextDiscountTier: number;
    };
  };
  endsAt: number;
  postConfirmationDetails?: TeamBuyDealDataPostConfirmationDetails;
};

export type TeamBuyDealDataCollection = {
  name: string;
  description: string;
  imageUrl: string;
};

export type TeamBuyDealDataPostConfirmationDetails = {
  title: string;
  description: string;
  cta?: {
    text: string;
    href: string;
  };
};

export type TeamBuyGameData = {
  name: string;
  slug: string;
};

export type TeamBuyData = {
  [gameSlug: string]: TeamBuyDealData[];
};

export type UseContractReadReturnType = ReturnType<typeof useContractRead>;

export type UseBalanceReturnType = ReturnType<typeof useBalance>;

export type UseAccountReturnType = ReturnType<typeof useAccount>;

export type SimpleHashAsset = {
  chain: string;
  contract_address: string;
  token_id: string;
  name?: string;
  description?: string;
  image_url?: string;
  video_url?: string;
  audio_url?: string;
  model_url?: string;
  previews: {
    image_small_url?: string;
    image_medium_url?: string;
    image_large_url?: string;
    image_opengraph_url?: string;
    blurhash?: string;
  };
  background_color?: string;
  external_url?: string;
  extra_metadata: {
    image_original_url?: string;
    animation_original_url?: string;
  };
  created_date?: string;
  status?: string;
  token_count: number;
  owner_count: number;
  owners: SimpleHashOwner[];
  contract: {
    type: string;
    name: string;
  };
  collection: {
    name: string;
    description?: string;
    image_url?: string;
    banner_image_url?: string;
    external_url?: string;
    twitter_username?: string;
    discord_url?: string;
    marketplace_pages?: { marketplace_name: string; collection_url: string; verified: boolean }[];
  };
};

export type SimpleHashOwner = {
  owner_address: string;
  quantity: number;
  first_acquired_date: string;
  last_acquired_date: string;
};
