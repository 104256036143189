import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { createRoot } from "react-dom/client";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import App from "./App";
import "./index.css";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import { WagmiConfig, createClient, configureChains, allChains } from "wagmi";
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { InjectedConnector } from "wagmi/connectors/injected";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { ADDITIONAL_WAGMI_CHAINS } from "./Constants";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { Typography, message } from "antd";
import amplitude from "amplitude-js";

amplitude.getInstance().init("cb0eb7ec6d084ed55d6ebb908d848095");

const themes = {
  dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/light-theme.css`,
};

const client = new ApolloClient({
  uri: process.env.REACT_APP_APOLLO_SERVER_URI || "http://localhost:4000/",
  cache: new InMemoryCache(),
});

const alchemyId = process.env.REACT_APP_ALCHEMY_ID;

const { chains, provider } = configureChains(
  [...allChains, ...ADDITIONAL_WAGMI_CHAINS],
  [
    jsonRpcProvider({
      rpc: chain => ({
        http: chain.rpcUrls.default,
      }),
      priority: 1,
      static: false,
    }),
    alchemyProvider({ alchemyId, priority: 2 }),
  ],
);

const wagmiClient = createClient({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: "wagmi",
      },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        qrcode: true,
      },
    }),
    new InjectedConnector({
      chains,
      options: {
        name: "Injected",
        shimDisconnect: true,
      },
    }),
  ],
  provider,
});

Sentry.init({
  dsn: "https://1ec22d5b9eb3468dad4a58d61fcd4118@o1131021.ingest.sentry.io/6175123",
  integrations: [
    new BrowserTracing(),
    new CaptureConsoleIntegration({
      levels: ["warn", "error"],
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.9,
  enabled: process.env.NODE_ENV === "production",
});

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <ApolloProvider client={client}>
    <ThemeSwitcherProvider themeMap={themes} defaultTheme={"dark"}>
      <WagmiConfig client={wagmiClient}>
        <Sentry.ErrorBoundary
          fallback={({ resetError }) => {
            message.error({
              duration: 0,
              content: (
                <Typography.Text>
                  An error occurred;{" "}
                  <a style={{ textDecoration: "underline" }} onClick={() => resetError()}>
                    click here to refresh the page
                  </a>
                </Typography.Text>
              ),
            });
          }}
          onReset={() => {
            window.location.reload(false);
          }}
        >
          <App />
        </Sentry.ErrorBoundary>
      </WagmiConfig>
    </ThemeSwitcherProvider>
  </ApolloProvider>,
);
