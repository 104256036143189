import { Layout, Spin } from "antd";
import { useContext, useEffect } from "react";
import PoolsCard from "../components/PoolsCard";
import { useEthersContext } from "../contexts/ethersContext";
import { getAllAggregatePools } from "../helpers/utils";
import { StoreContext } from "../providers/store";

const InvestPoolsHome = (): JSX.Element => {
  const { userSigner, readContracts, currentNetwork, isCorrectNetwork } = useEthersContext();
  const { aggregatePools, setAllAggregatePools } = useContext(StoreContext);

  useEffect(() => {
    if (readContracts) {
      getAllAggregatePools(readContracts, userSigner, currentNetwork, isCorrectNetwork).then(result =>
        setAllAggregatePools(result),
      );
    }
  }, [userSigner, readContracts]);

  // HACK HACK HACK
  const filteredPools = Object.values(aggregatePools).map(pool => {
    let name = "Curated Aggregate Pool";
    if (pool.address === "0xEC00BD50cd7F5479D8A3626931593E0B26d92717") {
      name = "Small Cap Aggregate Pool";
    } else if (pool.address === "0x27A207686148667FfBCc3Ec0CD163BCe87E13892") {
      name = "Large Cap Aggregate Pool";
    }

    return {
      name,
      ...pool,
    };
  });

  return !filteredPools ? (
    <Spin />
  ) : (
    <Layout style={{ padding: "1vh 10vw" }}>
      <PoolsCard pools={filteredPools} title="Deposit" link="/invest/pool" />
    </Layout>
  );
};

export default InvestPoolsHome;
