import { Card, Progress, Row, Typography } from "antd";
import { roundDecimal } from "../helpers/utils";
import { IndividualPool } from "../types/types";

type WithdrawableBalanceCardProps = {
  individualPool: IndividualPool;
};

const WithdrawableBalanceCard = ({ individualPool }: WithdrawableBalanceCardProps): JSX.Element => {
  return (
    <Card size="small" style={{ backgroundColor: "#150428" }}>
      <Row style={{ paddingTop: "1.5vh" }}>
        <Typography.Text style={{ fontSize: "15px" }}>Withdrawable Balance</Typography.Text>
      </Row>
      <Row style={{ paddingTop: "1.5vh" }}>
        <Progress
          strokeColor={{
            "0%": "#5100af",
            "20%": "#c42287",
            "40%": "#ff4172",
            "60%": "#fe6955",
            "80%": "#ffb824",
            "100%": "#ffdd66",
          }}
          percent={roundDecimal(individualPool.recipientBalance / individualPool.recipientMaxBalance, 2) * 100}
        />
        <Typography.Text style={{ fontSize: "15px" }}>
          {roundDecimal(individualPool.recipientBalance)} / {roundDecimal(individualPool.recipientMaxBalance)}{" "}
          {individualPool.poolToken.symbol}{" "}
        </Typography.Text>
      </Row>
    </Card>
  );
};

export default WithdrawableBalanceCard;
