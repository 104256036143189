import { Dropdown, Skeleton, Typography } from "antd";
import { blockExplorerLink, shortenAddress } from "../helpers/utils";
import { ethers } from "ethers";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { UseMutateFunction } from "react-query";
import { Button, Menu, Space } from "@openguild/design-system";

type AddressProps = {
  address: string;
  ensProvider: ethers.providers.StaticJsonRpcProvider | null;
  blockExplorer: string | undefined;
  logoutOfWeb3Modal: UseMutateFunction<void, Error, void, unknown>;
};

const Address = ({ address, blockExplorer, logoutOfWeb3Modal }: AddressProps): JSX.Element => {
  if (!address) {
    return <Skeleton avatar paragraph={{ rows: 1 }} />;
  }

  const menu = (
    <>
      <Menu
        items={[
          {
            key: "1",
            label: (
              <Typography.Link
                target="_blank"
                href={blockExplorer && blockExplorerLink(address, blockExplorer)}
                style={{ fontSize: "125%" }}
              >
                {shortenAddress(address, 4, 4)}
              </Typography.Link>
            ),
          },
          {
            type: "divider",
            style: { marginLeft: "1em", marginRight: "1em" },
          },
          {
            key: "2",
            label: (
              <a href="/profile/edit">
                <Space>
                  <UserOutlined />
                  <Typography.Text>Your Guild Profile</Typography.Text>
                </Space>
              </a>
            ),
          },
          {
            type: "divider",
            style: { marginLeft: "1em", marginRight: "1em" },
          },
          {
            key: "3",
            label: (
              <a onClick={() => logoutOfWeb3Modal()}>
                <Space>
                  <LogoutOutlined />
                  <Typography.Text>Disconnect Wallet</Typography.Text>
                </Space>
              </a>
            ),
          },
        ]}
      />
    </>
  );

  return (
    <Dropdown overlay={menu} placement="bottomRight">
      <Button size="large">{shortenAddress(address, 4, 4)}</Button>
    </Dropdown>
  );
};

export default Address;
