import { Contract, ethers } from "ethers";
import { AggregatePool, IndividualPool, Network } from "../types/types";
import { buildAggregatePool, buildIndividualPool } from "./builders";
import { isAggregatePoolContract, isIndividualPoolContract } from "./typeGuards";
import moment from "moment";

// Assumes a valid address is passed in
export const shortenAddress = (address: string, prefixLength = 3, suffixLength = 4): string => {
  return address.startsWith("0x")
    ? `${address.substring(0, prefixLength + 2)}...${address.substring(address.length - suffixLength)}`
    : `${address.substring(0, prefixLength)}...${address.substring(address.length - suffixLength)}`;
};

export const timeSince = (time: number): string => {
  return moment(Date.now() - time).fromNow();
};

export const calculateProgress = (currentNumber: number, targetNumber: number): number => {
  return roundDecimal((currentNumber / targetNumber) * 100, 1);
};

export const calculateDiscount = (discountPrice: number, originalPrice: number): number => {
  return roundDecimal(((originalPrice - discountPrice) / originalPrice) * 100, 1);
};

export const compareAddresses = (address1: string, address2: string): boolean => {
  return address1.toUpperCase() === address2.toUpperCase();
};

export const blockExplorerLink = (address: string, blockExplorer: string): string => {
  return `${blockExplorer || "https://etherscan.io/"}${"/address/"}${address}`;
};

export const findPool = (
  address: string,
  contracts: Record<string, Contract>,
  typeGuard: (contract: Contract) => boolean,
): Contract | undefined => {
  const poolContract = Object.entries(contracts).find(
    ([name, contract]) => compareAddresses(address, contract.address) && typeGuard(contract) && !name.endsWith("Proxy"),
  );
  return poolContract ? poolContract[1] : undefined;
};

export const findIndividualPoolContract = (address: string, contracts: Record<string, Contract>) => {
  const pool = findPool(address, contracts, isIndividualPoolContract);

  if (typeof pool === "undefined" || !isIndividualPoolContract(pool)) {
    return undefined;
  }

  return pool;
};

export const buildAndSetIndividualPool = async (
  userSigner: ethers.Signer | undefined,
  poolContract: Contract,
  configContract: Contract,
  network: Network,
  setIndividualPool: (individualPool: IndividualPool) => void,
  isCorrectNetwork: boolean,
): Promise<void> => {
  const result = await buildIndividualPool(userSigner, poolContract, configContract, network, isCorrectNetwork);
  setIndividualPool(result);
};

export const buildAndSetAggregatePool = async (
  userSigner: ethers.Signer | undefined,
  poolContract: Contract,
  allContracts: Record<string, Contract>,
  network: Network,
  setAggregatePool: (aggregatePool: AggregatePool) => void,
  isCorrectNetwork: boolean,
): Promise<void> => {
  const result = await buildAggregatePool(userSigner, poolContract, allContracts, network, isCorrectNetwork);
  setAggregatePool(result);
};

const _isContractNameAPool = (contractName: string): boolean => {
  return (
    (contractName.startsWith("Aggregate") || contractName.startsWith("Individual")) &&
    !(contractName.endsWith("Implementation") || contractName.endsWith("Proxy"))
  );
};

export const getAllAggregatePools = async (
  readContracts: Record<string, Contract>,
  userSigner: ethers.Signer | undefined,
  network: Network,
  isCorrectNetwork: boolean,
): Promise<Array<AggregatePool>> => {
  const validContracts = _getValidContracts(readContracts, isAggregatePoolContract);
  return Promise.all(
    validContracts.map(contract => buildAggregatePool(userSigner, contract, readContracts, network, isCorrectNetwork)),
  );
};

export const getAllIndividualPools = async (
  readContracts: Record<string, Contract>,
  userSigner: ethers.Signer | undefined,
  network: Network,
  isCorrectNetwork: boolean,
): Promise<Array<IndividualPool>> => {
  const validContracts = _getValidContracts(readContracts, isIndividualPoolContract);

  return Promise.all(
    validContracts.map(contract =>
      buildIndividualPool(userSigner, contract, readContracts["ProtocolConfig"], network, isCorrectNetwork),
    ),
  );
};

const _getValidContracts = (
  readContracts: Record<string, Contract>,
  typeGuard: (contract: Contract) => boolean,
): Contract[] => {
  const validContracts = Object.entries(readContracts)
    .filter(([name, contract]) => {
      return _isContractNameAPool(name) && typeGuard(contract);
    })
    .map(([, contract]) => contract);

  if (Object.keys(validContracts).length === 0) {
    return [];
  }
  return validContracts;
};

export const roundDecimal = (input: number, decimalPlaces = 4): number => {
  return Math.round((input + Number.EPSILON) * 10 ** decimalPlaces) / 10 ** decimalPlaces;
};

export const capitalize = (input: string): string => {
  return input.charAt(0).toUpperCase() + input.slice(1);
};
