import { Alert, Row, Space, Typography } from "antd";
import { Contract } from "ethers";
import { shortenAddress } from "../helpers/utils";
import { AggregatePool } from "../types/types";
import { Form, InputNumber, SubmitButton } from "formik-antd";
import { ErrorMessage, FieldArray, Formik } from "formik";
import * as Yup from "yup";
import * as Toast from "../helpers/toasts";
import { red } from "@ant-design/colors";
import { useEthersContext } from "../contexts/ethersContext";
import { PrecisionConverter } from "../helpers/PrecisionConverter";

type PoolManagerFormProps = {
  pool: AggregatePool;
  poolContract: Contract | undefined;
};

// TODO: Make sure that sum is off by less than margin of error
const PoolManagerForm = ({ pool, poolContract }: PoolManagerFormProps): JSX.Element => {
  const { tx } = useEthersContext();

  const poolAllocationSchema = Yup.object().shape({
    poolAllocations: Yup.array().of(
      Yup.object().shape({
        address: Yup.string().required(),
        allocation: Yup.number()
          .typeError("Allocation percentage must be a number")
          .min(0, "Allocation percentage cannot be negative")
          .max(100, "Allocation percentage must be at most 100")
          .required("Must add an allocation percentage"),
      }),
    ),
  });

  return (
    <>
      <Row style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography.Title level={4}>Individual Pool Allocations</Typography.Title>
      </Row>

      <Formik
        initialValues={{ poolAllocations: pool.individualPoolAllocations }}
        validationSchema={poolAllocationSchema}
        onSubmit={async ({ poolAllocations }) => {
          const precisionConverter = new PrecisionConverter(pool.poolToken.decimal);
          const allocationContractValues = poolAllocations.map(({ allocation }) =>
            precisionConverter.toContractAmount((allocation * pool.percentageDecimal).toString()).toString(),
          );
          if (!poolContract) {
            Toast.contractFailureNotification();
          } else {
            await tx(
              poolContract.setPoolAllocations(allocationContractValues),
              // eslint-disable-next-line
              (update: any) => {
                if (update && (update.status === "confirmed" || update.status === 1)) {
                  Toast.poolAllocationSuccessNotification();
                }
              },
            );
          }
        }}
        validate={({ poolAllocations }) => {
          const errors: { [variable: string]: string } = {};
          const allocationSum = poolAllocations.map(({ allocation }) => allocation).reduce((sum, a) => sum + a, 0);

          if (!isWithinAllocationMarginOfError(allocationSum, pool)) {
            errors["poolAllocations"] = "Sum of allocations doesn't add up to 100";
          }
          return errors;
        }}
        enableReinitialize
      >
        {({ values, errors }) => {
          const poolAllocationSum = values.poolAllocations
            .map(({ allocation }) => allocation)
            .reduce((sum, a) => sum + a, 0);
          return (
            <Form layout="horizontal" labelCol={{ span: 4 }} wrapperCol={{ span: 14 }}>
              <Typography.Title level={5}>
                Total Percentage:{" "}
                <span style={{ ...(!!errors.poolAllocations && { color: red.primary }) }}>{poolAllocationSum}%</span>
              </Typography.Title>
              <FieldArray
                name="poolAllocations"
                render={() => (
                  <>
                    {values.poolAllocations.map(({ address }, index) => {
                      return (
                        <Form.Item
                          key={index}
                          label={shortenAddress(address)}
                          name={`poolAllocations.${index}.allocation`}
                        >
                          <InputNumber
                            name={`poolAllocations.${index}.allocation`}
                            placeholder="Percentage (i.e. 33.333)"
                            data-lpignore="true"
                            controls={false}
                          />
                        </Form.Item>
                      );
                    })}
                  </>
                )}
              />
              <Space direction="vertical">
                <ErrorMessage
                  name="poolAllocations"
                  render={err => typeof err === "string" && <Alert message={err} type="error" />}
                />
                <SubmitButton type="primary" disabled={values.poolAllocations.length === 0 || !!errors.poolAllocations}>
                  Set allocation
                </SubmitButton>
              </Space>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

const isWithinAllocationMarginOfError = (amount: number, aggregatePool: AggregatePool): boolean => {
  const oneHundred = 100 * aggregatePool.percentageDecimal;
  const currPercentage = amount * aggregatePool.percentageDecimal;
  return Math.abs(oneHundred - currPercentage) <= aggregatePool.allocationMarginOfError;
};

export default PoolManagerForm;
