import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Alert, Layout, Space, Spin, Typography } from "antd";
import { useContext, useEffect, useState } from "react";
import PoolsCard from "../components/PoolsCard";
import { useEthersContext } from "../contexts/ethersContext";
import { getAllIndividualPools } from "../helpers/utils";
import { StoreContext } from "../providers/store";

const ReceivePoolsHome = (): JSX.Element => {
  const { userSigner, readContracts, currentNetwork, isCorrectNetwork } = useEthersContext();
  const [currentUserAddress, setCurrentUserAddress] = useState<string>();
  const { individualPools, setAllIndividualPools } = useContext(StoreContext);

  useEffect(() => {
    if (readContracts) {
      getAllIndividualPools(readContracts, userSigner, currentNetwork, isCorrectNetwork).then(result =>
        setAllIndividualPools(result),
      );
      userSigner?.getAddress().then(result => setCurrentUserAddress(result));
    }
  }, [userSigner, readContracts]);

  const filteredPools = Object.values(individualPools)?.filter(pool => pool.recipientAddress === currentUserAddress);

  return !filteredPools ? (
    <Spin />
  ) : (
    <Layout style={{ padding: "1vh 10vw" }}>
      {!filteredPools.length && Object.keys(individualPools)?.length != 0 && (
        <Alert
          message={
            <Space>
              <ExclamationCircleOutlined />
              <Typography.Text style={{ fontSize: "14px", color: "white" }}>You are not a recipient.</Typography.Text>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSe9B8iwP5fy4nXd3heArbqV0nF3DX6TA0Hkz-3v63KNe2xypg/viewform"
                style={{ fontSize: "14px", color: "white", textDecoration: "underline" }}
              >
                Click here to sign up your guild!
              </a>
            </Space>
          }
          showIcon={false}
          style={{ marginTop: 1, backgroundColor: "inherit", textAlign: "center", padding: ".5em" }}
          banner
        />
      )}
      <PoolsCard pools={filteredPools} title="Receive" link="/receive/pool" />
    </Layout>
  );
};

export default ReceivePoolsHome;
