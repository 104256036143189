import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Alert, Layout, Space, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import PoolsCard from "../components/PoolsCard";
import { useEthersContext } from "../contexts/ethersContext";
import { getAllAggregatePools } from "../helpers/utils";
import { AggregatePool } from "../types/types";

const ManagePoolsHome = (): JSX.Element => {
  const { userSigner, readContracts, currentNetwork, isCorrectNetwork } = useEthersContext();
  const [aggregatePools, setAggregatePools] = useState<ReadonlyArray<AggregatePool>>();
  const [currentUserAddress, setCurrentUserAddress] = useState<string>();

  useEffect(() => {
    if ((isCorrectNetwork || isCorrectNetwork === undefined) && readContracts) {
      getAllAggregatePools(readContracts, userSigner, currentNetwork, isCorrectNetwork).then(result =>
        setAggregatePools(result),
      );
      userSigner?.getAddress().then(result => setCurrentUserAddress(result));
    }
  }, [userSigner, readContracts]);

  const filteredPools =
    isCorrectNetwork === false ? [] : aggregatePools?.filter(pool => pool.poolManagerAddress === currentUserAddress);

  return !filteredPools ? (
    <Spin />
  ) : (
    <Layout style={{ padding: "1vh 10vw" }}>
      {!filteredPools.length && aggregatePools?.length != 0 && (
        <Alert
          message={
            <Space>
              <ExclamationCircleOutlined />
              <Typography.Text style={{ fontSize: "14px", color: "white" }}>
                You are not a pool manager.
              </Typography.Text>
            </Space>
          }
          showIcon={false}
          style={{ marginTop: 1, backgroundColor: "inherit", textAlign: "center", padding: ".5em" }}
          banner
        />
      )}
      <PoolsCard pools={filteredPools} title="Manage" link="/manage/pool" />
    </Layout>
  );
};

export default ManagePoolsHome;
