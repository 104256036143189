import { Chain } from "wagmi";
import { IndividualPool, AggregatePool, ERC20, Network, PoolTypeEnum } from "./types/types";

// MY INFURA_ID, SWAP IN YOURS FROM https://infura.io/dashboard/ethereum
export const INFURA_ID = "460f40a260564ac4a4f4b3fffb032dad";

// MY ETHERSCAN_ID, SWAP IN YOURS FROM https://etherscan.io/myapikey
export const ETHERSCAN_KEY = "PSW8C433Q667DVEX5BCRMGNAH9FSGFZ7Q8";

// BLOCKNATIVE ID FOR Notify.js:
export const BLOCKNATIVE_DAPPID = "0b58206a-f3c0-4701-a62f-73c7243e8c77";

type PoolInfoType = {
  [network in keyof typeof NETWORKS]: {
    aggregatePools: {
      [address: string]: PartialAggregatePool;
    };
    individualPools: {
      [address: string]: PartialIndividualPool;
    };
  };
};

// These types effectively replace the poolToken ERC20 type with a Partial of the ERC20 type, allowing
// for more flexibility when choosing which values to cache and which not to cache
export type PartialERC20 = Partial<ERC20>;
export type PartialAggregatePool = Partial<Omit<AggregatePool, "poolToken"> & { poolToken: PartialERC20 }>;
export type PartialIndividualPool = Partial<Omit<IndividualPool, "poolToken"> & { poolToken: PartialERC20 }>;

// For each network's deployed aggregate/individual pools, stores
// values that don't change often so that we don't need to make network calls.
export const POOL_INFO: PoolInfoType = {
  polygon: {
    aggregatePools: {
      "0x2d63a0a2f4f081cdbbb4bd785537b3959f3b37ac": {
        address: "0x2d63a0a2f4f081cdbbb4bd785537b3959f3b37ac",
        type: PoolTypeEnum.Aggregate,
        protocolTakeRate: 0,
        poolToken: {
          address: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
          decimal: 6,
          symbol: "USDT",
          name: "(PoS) Tether USD",
        },
        poolManagerAddress: "0x7173ec91fc81c910Af8DaFb4833AC1Fd2761ED3b",
        currentIndividualPoolsLength: 10,
        managerTakeRate: 0,
        percentageDecimal: 10000000000000000,
        individualPoolAllocations: [],
        allocationMarginOfError: 10000,
      },
      "0x27A207686148667FfBCc3Ec0CD163BCe87E13892": {
        address: "0x27A207686148667FfBCc3Ec0CD163BCe87E13892",
        type: PoolTypeEnum.Aggregate,
        protocolTakeRate: 0,
        poolToken: {
          address: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
          decimal: 6,
          symbol: "USDT",
          name: "(PoS) Tether USD",
        },
        poolManagerAddress: "0x7173ec91fc81c910Af8DaFb4833AC1Fd2761ED3b",
        currentIndividualPoolsLength: 10,
        managerTakeRate: 0,
        percentageDecimal: 10000000000000000,
        individualPoolAllocations: [],
        allocationMarginOfError: 10000,
      },
      "0xEC00BD50cd7F5479D8A3626931593E0B26d92717": {
        address: "0xEC00BD50cd7F5479D8A3626931593E0B26d92717",
        type: PoolTypeEnum.Aggregate,
        protocolTakeRate: 0,
        poolToken: {
          address: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
          decimal: 6,
          symbol: "USDT",
          name: "(PoS) Tether USD",
        },
        poolManagerAddress: "0x7173ec91fc81c910Af8DaFb4833AC1Fd2761ED3b",
        currentIndividualPoolsLength: 10,
        managerTakeRate: 0,
        percentageDecimal: 10000000000000000,
        individualPoolAllocations: [],
        allocationMarginOfError: 10000,
      },
    },
    individualPools: {},
  },
  binance: {
    aggregatePools: {},
    individualPools: {},
  },
  polytest: {
    aggregatePools: {},
    individualPools: {},
  },
  localhost: {
    aggregatePools: {},
    individualPools: {},
  },
  mainnet: {
    aggregatePools: {},
    individualPools: {},
  },
  kovan: {
    aggregatePools: {},
    individualPools: {},
  },
  rinkeby: {
    aggregatePools: {},
    individualPools: {},
  },
  ropsten: {
    aggregatePools: {},
    individualPools: {},
  },
  goerli: {
    aggregatePools: {},
    individualPools: {},
  },
  xdai: {
    aggregatePools: {},
    individualPools: {},
  },
  localArbitrum: {
    aggregatePools: {},
    individualPools: {},
  },
  localArbitrumL1: {
    aggregatePools: {},
    individualPools: {},
  },
  rinkebyArbitrum: {
    aggregatePools: {},
    individualPools: {},
  },
  arbitrum: {
    aggregatePools: {},
    individualPools: {},
  },
  localOptimismL1: {
    aggregatePools: {},
    individualPools: {},
  },
  localOptimism: {
    aggregatePools: {},
    individualPools: {},
  },
  kovanOptimism: {
    aggregatePools: {},
    individualPools: {},
  },
  optimism: {
    aggregatePools: {},
    individualPools: {},
  },
  localAvalanche: {
    aggregatePools: {},
    individualPools: {},
  },
  fujiAvalanche: {
    aggregatePools: {},
    individualPools: {},
  },
  mainnetAvalanche: {
    aggregatePools: {},
    individualPools: {},
  },
  testnetHarmony: {
    aggregatePools: {},
    individualPools: {},
  },
  mainnetHarmony: {
    aggregatePools: {},
    individualPools: {},
  },
};

// This address corresponds to OpenGuild's multisig gnosis safe
export const POOL_MANAGERS = ["0xF14E2d4c41636363A8f451e1aF119649B83C3fB8"];

export const NETWORKS = {
  localhost: {
    name: "localhost",
    key: "localhost",
    color: "#666666",
    chainId: 31337,
    blockExplorer: "",
    rpcUrl: "http://" + window.location.hostname + ":8545",
  },
  mainnet: {
    name: "mainnet",
    key: "mainnet",
    color: "#ff8b9e",
    chainId: 1,
    rpcUrl: `https://mainnet.infura.io/v3/${INFURA_ID}`,
    blockExplorer: "https://etherscan.io/",
  },
  kovan: {
    name: "kovan",
    key: "kovan",
    color: "#7003DD",
    chainId: 42,
    rpcUrl: `https://kovan.infura.io/v3/${INFURA_ID}`,
    blockExplorer: "https://kovan.etherscan.io/",
    faucet: "https://gitter.im/kovan-testnet/faucet", // https://faucet.kovan.network/
  },
  rinkeby: {
    name: "rinkeby",
    key: "rinkeby",
    color: "#e0d068",
    chainId: 4,
    rpcUrl: `https://rinkeby.infura.io/v3/${INFURA_ID}`,
    faucet: "https://faucet.rinkeby.io/",
    blockExplorer: "https://rinkeby.etherscan.io/",
  },
  ropsten: {
    name: "ropsten",
    key: "ropsten",
    color: "#F60D09",
    chainId: 3,
    faucet: "https://faucet.ropsten.be/",
    blockExplorer: "https://ropsten.etherscan.io/",
    rpcUrl: `https://ropsten.infura.io/v3/${INFURA_ID}`,
  },
  goerli: {
    name: "goerli",
    key: "goerli",
    color: "#0975F6",
    chainId: 5,
    faucet: "https://goerli-faucet.slock.it/",
    blockExplorer: "https://goerli.etherscan.io/",
    rpcUrl: `https://goerli.infura.io/v3/${INFURA_ID}`,
  },
  xdai: {
    name: "xdai",
    key: "xdai",
    color: "#48a9a6",
    chainId: 100,
    price: 1,
    gasPrice: 1000000000,
    rpcUrl: "https://dai.poa.network",
    faucet: "https://xdai-faucet.top/",
    blockExplorer: "https://blockscout.com/poa/xdai/",
  },
  polygon: {
    name: "Polygon",
    key: "polygon",
    color: "#2bbdf7",
    chainId: 137,
    price: 1,
    gasPrice: 1000000000,
    rpcUrl: "https://polygon-mainnet.g.alchemy.com/v2/DIafJ59S72oeA2WiGL9S5MdKVUOB8yPK",
    faucet: "https://faucet.matic.network/",
    blockExplorer: "https://polygonscan.com/",
  },
  polytest: {
    name: "Polygon Mumbai (Testnet)",
    key: "polytest",
    color: "#92D9FA",
    chainId: 80001,
    price: 1,
    gasPrice: 1000000000,
    rpcUrl: "https://polygon-mumbai.g.alchemy.com/v2/NGWvzLQCBafuFiiD67eLWYJV-vjDL8Kp",
    faucet: "https://faucet.matic.network/",
    blockExplorer: "https://mumbai.polygonscan.com/",
  },
  localArbitrum: {
    name: "localArbitrum",
    key: "localArbitrum",
    color: "#50a0ea",
    chainId: 153869338190755,
    blockExplorer: "",
    rpcUrl: `http://localhost:8547`,
  },
  localArbitrumL1: {
    name: "localArbitrumL1",
    key: "localArbitrumL1",
    color: "#50a0ea",
    chainId: 44010,
    blockExplorer: "",
    rpcUrl: `http://localhost:7545`,
  },
  rinkebyArbitrum: {
    name: "Arbitrum Testnet",
    key: "rinkebyAritrum",
    color: "#50a0ea",
    chainId: 421611,
    blockExplorer: "https://rinkeby-explorer.arbitrum.io/#/",
    rpcUrl: `https://rinkeby.arbitrum.io/rpc`,
  },
  arbitrum: {
    name: "Arbitrum",
    key: "arbitrum",
    color: "#50a0ea",
    chainId: 42161,
    blockExplorer: "https://explorer.arbitrum.io/#/",
    rpcUrl: `https://arb1.arbitrum.io/rpc`,
    gasPrice: 0,
  },
  localOptimismL1: {
    name: "localOptimismL1",
    key: "localOptimismL1",
    color: "#f01a37",
    chainId: 31337,
    blockExplorer: "",
    rpcUrl: "http://" + window.location.hostname + ":9545",
  },
  localOptimism: {
    name: "localOptimism",
    key: "localOptimisim",
    color: "#f01a37",
    chainId: 420,
    blockExplorer: "",
    rpcUrl: "http://" + window.location.hostname + ":8545",
    gasPrice: 0,
  },
  kovanOptimism: {
    name: "kovanOptimism",
    key: "kovanOptimism",
    color: "#f01a37",
    chainId: 69,
    blockExplorer: "https://kovan-optimistic.etherscan.io/",
    rpcUrl: `https://kovan.optimism.io`,
    gasPrice: 0,
  },
  optimism: {
    name: "optimism",
    key: "optimism",
    color: "#f01a37",
    chainId: 10,
    blockExplorer: "https://optimistic.etherscan.io/",
    rpcUrl: `https://mainnet.optimism.io`,
  },
  localAvalanche: {
    name: "localAvalanche",
    key: "localAvalanche",
    color: "#666666",
    chainId: 43112,
    blockExplorer: "",
    rpcUrl: `http://localhost:9650/ext/bc/C/rpc`,
    gasPrice: 225000000000,
  },
  fujiAvalanche: {
    name: "fujiAvalanche",
    key: "fujiAvalanche",
    color: "#666666",
    chainId: 43113,
    blockExplorer: "https://cchain.explorer.avax-test.network/",
    rpcUrl: `https://api.avax-test.network/ext/bc/C/rpc`,
    gasPrice: 225000000000,
  },
  mainnetAvalanche: {
    name: "Avalanche",
    key: "mainnetAvalanche",
    color: "#666666",
    chainId: 43114,
    blockExplorer: "https://cchain.explorer.avax.network/",
    rpcUrl: `https://api.avax.network/ext/bc/C/rpc`,
    gasPrice: 225000000000,
  },
  testnetHarmony: {
    name: "Harmony Testnet",
    key: "testnetHarmony",
    color: "#00b0ef",
    chainId: 1666700000,
    blockExplorer: "https://explorer.pops.one/",
    rpcUrl: `https://api.s0.b.hmny.io`,
    gasPrice: 1000000000,
  },
  mainnetHarmony: {
    name: "Harmony",
    key: "mainnetHarmony",
    color: "#00b0ef",
    chainId: 1666600000,
    blockExplorer: "https://explorer.harmony.one/",
    rpcUrl: `https://api.harmony.one`,
    gasPrice: 1000000000,
  },
  binance: {
    name: "Binance Smart Chain",
    key: "binance",
    color: "#ffff00",
    chainId: 56,
    blockExplorer: "https://bscscan.com/",
    rpcUrl: "https://bsc-dataseed.binance.org/",
  },
};

export const NETWORK = (chainId: number): Network | undefined => {
  for (const n in NETWORKS) {
    const network = n as keyof typeof NETWORKS;
    if (NETWORKS[network].chainId === chainId) {
      return NETWORKS[network];
    }
  }
};

export const GUILD_NAMES = {
  ["0x2f42f67f31AF0f96CF267Fdd69e20011D5CC9A81"]: "AxieGlobal",
  ["0x6B2389a632BB8f921A941B440747bF08C27CD8c3"]: "Vicious Rankers",
  ["0x799919994942f5Ab5126d9c2Fb5BF23f414460CF"]: "Jackpot",
  ["0x823a2dEc795ce8bFDbdD945cE2453eA646C72E01"]: "JaxieWick",
  ["0xF9d63699F24EBCC5C8C1361bd5D5e8cDd75ea27b"]: "Champion NFT",
  ["0xFA1B216C4359E97a95ef497d82D9643813c52CB8"]: "Binary Pogs",
  ["0xa98bcfe6f1c4fca1aab31c70B2a10Ba8a0C45387"]: "Axie Buzz",
  ["0xce3AB0893b64edB2e0B4826220C07d35D3fDf8e2"]: "Tefaria",
  ["0xdE1107D393D9eB0dE4A45e862119360Aa1f3bD88"]: "AxieBob",
};

export const AGGREGATE_POOLS = [
  {
    address: "0x2D63a0a2F4F081CDbBb4Bd785537b3959f3B37AC",
    index: "A1",
    network: "polygon",
  },
];

export const AVAILABLE_NETWORKS = ["polygon", "mainnetAvalanche", "binance", "mainnetHarmony", "polytest", "localhost"];
export const AVAILABLE_CHAINS = [137, 43114, 56, 1666600000, 80001];

export const ADDITIONAL_WAGMI_CHAINS: Chain[] = [
  {
    id: 43114,
    name: "Avalanche",
    network: "avalanche",
    nativeCurrency: {
      decimals: 18,
      name: "Avalanche",
      symbol: "AVAX",
    },
    rpcUrls: {
      default: "https://api.avax.network/ext/bc/C/rpc",
    },
    blockExplorers: {
      default: { name: "SnowTrace", url: "https://snowtrace.io" },
      etherscan: { name: "SnowTrace", url: "https://snowtrace.io" },
    },
    testnet: false,
  },
  {
    id: 56,
    name: "Binance Smart Chain",
    network: "Smart Chain",
    nativeCurrency: {
      name: "Binance Coin",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: {
      alchemy: "",
      infura: "",
      default: "https://bsc-dataseed.binance.org/",
    },
    blockExplorers: {
      etherscan: { name: "BSC Scan", url: "https://bscscan.com" },
      default: { name: "BSC Scan", url: "https://bscscan.com" },
    },
  },
  {
    id: 1666600000,
    name: "Harmony Mainnet",
    network: "Harmony Mainnet",
    nativeCurrency: {
      name: "Harmony One",
      symbol: "ONE",
      decimals: 18,
    },
    rpcUrls: {
      alchemy: "",
      infura: "",
      default: "https://api.harmony.one",
    },
    blockExplorers: {
      etherscan: { name: "Harmony Block Explorer", url: "https://explorer.harmony.one" },
      default: { name: "Harmony Block Explorer", url: "https://explorer.harmony.one" },
    },
  },
];

export const DEFAULT_DISCORD_INVITE_LINK = "http://discord.gg/sKmZjUJF3N";

export const MARKETPLACE_SUPPORTED_NETWORKS = ["polygon"];
