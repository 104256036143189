import { notification } from "antd";
import { IndividualPool, AggregatePool } from "../types/types";
import { shortenAddress, roundDecimal } from "./utils";

const commonArgs = {
  duration: 4.5,
};

const errorArgs = {
  style: { borderRadius: 6, border: "0.5px solid #DE2016" },
};

const successArgs = {
  style: { borderRadius: 6, border: "0.5px solid #16DE36" },
};

export const showError = (message: string | JSX.Element): void => {
  const args = {
    ...commonArgs,
    ...errorArgs,
    message,
  };
  notification.error(args);
};

export const showSuccess = (message: string | JSX.Element): void => {
  const args = {
    ...commonArgs,
    ...successArgs,
    message,
  };
  notification.success(args);
};

export const investorRoleFailureNotification = (address: string): void => {
  showError(`Current user is not an investor in pool ${shortenAddress(address)}`);
};

export const userFailureNotification = (): void => {
  showError(`Current user does not exist or is not logged in.`);
};

export const approveFailureNotification = (): void => {
  showError(`OpenGuild is already approved to spend your pool tokens.`);
};

export const investSuccessNotification = (investInput: string, pool: IndividualPool | AggregatePool): void => {
  showSuccess(`Successfully invested ${roundDecimal(parseFloat(investInput))} ${pool.poolToken.symbol}!`);
};

export const claimSuccessNotification = (): void => {
  showSuccess(`Successfully claimed your dividends!`);
};
export const claimBalanceFailureNotification = (): void => {
  showError(`There are no dividends to claim!`);
};

export const undeployTokenFailureNotification = (): void => {
  showError(`Select at least one token to undeploy!`);
};

export const undeploySuccessNotification = (): void => {
  showSuccess(`Successfully undeployed your tokens!`);
};

export const contributeSuccessNotification = (contributeInput: string, symbol: string): void => {
  showSuccess(`Successfully contributed ${contributeInput} ${symbol}!`);
};

export const withdrawBalanceFailureNotification = (input: string): void => {
  showError("Withdrawal amount cannot exceed " + input);
};
export const withdrawSuccessNotification = (withdrawInput: string, symbol: string): void => {
  showSuccess(`Successfully withdrew ${withdrawInput} ${symbol}!`);
};

export const contractFailureNotification = (): void => {
  showError(`The pool contract does not exist!`);
};

export const allowanceFailureNotification = (): void => {
  showError("OpenGuild was not approved to spend your capital");
};

export const approveSuccessNotification = (symbol: string): void => {
  showSuccess(`Successfully approved OpenGuild to contribute your ${symbol}!`);
};

export const balanceFailureNotification = (symbol: string): void => {
  showError(`Input amount cannot exceed your ${symbol} balance!`);
};

export const poolAllocationSuccessNotification = (): void => {
  showSuccess("Successfully allocated!");
};

export const undeployedAmountFailureNotification = (tokenIdInput: string): void => {
  showError(`Token ${tokenIdInput} does not have any capital left to undeploy!`);
};
