import { Button, Card, Col, Layout, Row, Space, Spin, Table, Typography } from "antd";
import { useContext, useEffect, useState } from "react";
import InvestForm from "../components/InvestForm";
import CapitalCard from "../components/CapitalCard";
import { useEthersContext } from "../contexts/ethersContext";
import { isBasePoolContract } from "../helpers/typeGuards";
import { buildAndSetAggregatePool, findPool, roundDecimal, shortenAddress } from "../helpers/utils";
import { ActionTab, AggregatePool, InfoTab } from "../types/types";
import * as Toast from "../helpers/toasts";
import AuthStatusButton from "../components/AuthStatusButton";
import SharesCard from "../components/SharesCard";
import AnnualYieldCard from "../components/AnnualYieldCard";
import { Contract } from "ethers";
import AddressLink from "../components/AddressLink";
import { StoreContext } from "../providers/store";
import { EMPTY_AGGREGATE_POOL } from "../helpers/emptyPools";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";

type InvestPoolHomeProps = {
  poolAddress: string;
};

const InvestPoolHome = (): JSX.Element => {
  const { poolAddress } = useParams() as InvestPoolHomeProps;

  const { userSigner, writeContracts, readContracts, currentNetwork, isCorrectNetwork, tx } = useEthersContext();
  const { aggregatePools, updateAggregatePool } = useContext(StoreContext);

  const [loading, setLoading] = useState(true);
  const [aggregatePool, setAggregatePool] = useState<AggregatePool>(EMPTY_AGGREGATE_POOL);

  const [currentInfoTab, setCurrentInfoTab] = useState<InfoTab>(InfoTab.PoolStats);
  const [currentActionTab, setCurrentActionTab] = useState<ActionTab>(ActionTab.Deposit);
  const [poolContract, setPoolContract] = useState<Contract | undefined>(
    findPool(poolAddress, readContracts, isBasePoolContract),
  );

  useEffect(() => {
    (async function () {
      const allContracts = !userSigner || isCorrectNetwork === false ? readContracts : writeContracts;
      // we are guaranteed to have contracts be of length at least 0, so continue to keep loading state
      if (Object.keys(allContracts).length === 0) {
        return;
      }

      const currentAddress = !userSigner ? "0x0" : await userSigner.getAddress();
      if (typeof aggregatePools[poolAddress] !== "undefined" && currentAddress === "0x0") {
        setAggregatePool(aggregatePools[poolAddress]);
        setLoading(false);
      } else {
        const newPoolContract = findPool(poolAddress, allContracts, isBasePoolContract);
        setPoolContract(newPoolContract);
        if (newPoolContract) {
          buildAndSetAggregatePool(
            userSigner,
            newPoolContract,
            allContracts,
            currentNetwork,
            pool => {
              updateAggregatePool(pool);
              setAggregatePool(pool);
              setLoading(false);
            },
            isCorrectNetwork,
          );
        } else {
          setLoading(false);
        }
      }
    })();
  }, [userSigner, readContracts, writeContracts, tx]);

  if (loading) {
    return <Spin />;
  }

  if (!poolContract) {
    return (
      <Layout>
        <Row>
          <Col
            md={{ span: 24 }}
            lg={{ span: 16, offset: 1 }}
            xl={{ span: 16, offset: 2 }}
            xxl={{ span: 16, offset: 3 }}
          >
            <Space direction="vertical">
              <Typography.Text strong style={{ fontSize: "28px" }}>
                Aggregate pool doesn't exist
              </Typography.Text>
              <Typography.Link href="/">
                <Space>
                  <ArrowLeftOutlined />
                  Click here to return home
                </Space>
              </Typography.Link>
            </Space>
          </Col>
        </Row>
      </Layout>
    );
  }

  const infoTabContents = () => {
    if (currentInfoTab === InfoTab.PoolStats) {
      return (
        <Layout>
          <Row>
            <Col span={12} style={{ paddingRight: "0.5vw" }}>
              <CapitalCard
                undeployedCapital={roundDecimal(aggregatePool.totalUndeployed)}
                deployedCapital={roundDecimal(aggregatePool.totalDeployed)}
                symbol={aggregatePool.poolToken.symbol}
              />
            </Col>
            <Col span={12} style={{ paddingLeft: "0.5vw" }}>
              <AnnualYieldCard pool={aggregatePool} />
            </Col>
          </Row>
        </Layout>
      );
    } else if (currentInfoTab === InfoTab.InvestorInfo) {
      return (
        <Layout>
          <Row>
            <Col span={12} style={{ paddingRight: "0.5vw" }}>
              <CapitalCard
                undeployedCapital={aggregatePool.currentInvestorInfo.undeployedCapital}
                deployedCapital={aggregatePool.currentInvestorInfo.deployedCapital}
                symbol={aggregatePool.poolToken.symbol}
              />
            </Col>
            <Col span={12} style={{ paddingLeft: "0.5vw" }}>
              <SharesCard
                shares={aggregatePool.currentInvestorInfo.warrantTokenBalance}
                totalSupply={aggregatePool.totalSupply}
              />
            </Col>
          </Row>
        </Layout>
      );
    } else if (currentInfoTab === InfoTab.IndividualPools) {
      const columns = [
        {
          title: "Address",
          dataIndex: "address",
          key: "address",
          render: (address: string) => <AddressLink address={address} />,
        },
        {
          title: "Recipient",
          dataIndex: "recipientAddress",
          key: "recipientAddress",
          render: (recipientAddress: string) => <AddressLink address={recipientAddress} />,
        },
        {
          title: "Allocation %",
          dataIndex: "allocation",
          key: "allocation",
          render: (claimedDividends: number) => <span>{claimedDividends} %</span>,
        },
        {
          title: "Annual Yield",
          dataIndex: "annualYield",
          key: "annualYield",
          render: (annualYield: number) => <span>{roundDecimal(annualYield, 1)} %</span>,
        },
      ];
      const dataSource = aggregatePool.currentIndividualPools.map(pool => {
        return {
          key: pool.address,
          ...pool,
        };
      });
      return (
        <Layout>
          <Row>
            <Col flex="auto">
              <Table columns={columns} dataSource={dataSource} pagination={false} />
            </Col>
          </Row>
        </Layout>
      );
    }
  };

  const actionTabContents = () => {
    if (currentActionTab === ActionTab.Deposit) {
      return (
        <Layout>
          <Row>
            <Col span={24}>
              <InvestForm poolContract={poolContract} pool={aggregatePool} setPool={updateAggregatePool} />
            </Col>
          </Row>
        </Layout>
      );
    } else if (currentActionTab === ActionTab.Claim) {
      return (
        <Layout>
          {aggregatePool.protocolTakeRate > 0 && (
            <Row style={{ paddingBottom: "2em" }}>
              <Col span={10}>
                <Typography.Text style={{ fontSize: "15px", color: "#B7B7B7" }}>Protocol Take Rate</Typography.Text>
              </Col>
              <Col span={14}>
                <Typography.Text style={{ fontSize: "15px", color: "#B7B7B7", float: "right" }}>
                  {!aggregatePool.currentInvestorInfo.unclaimedDividends
                    ? 0
                    : roundDecimal(
                        aggregatePool.currentInvestorInfo.unclaimedDividends * aggregatePool.protocolTakeRate,
                        2,
                      )}{" "}
                  {aggregatePool.poolToken.symbol} ({aggregatePool.protocolTakeRate * 100}%)
                </Typography.Text>
              </Col>
            </Row>
          )}
          <Row>
            <Col span={24}>
              <Button
                disabled={aggregatePool.currentInvestorInfo.unclaimedDividends === 0 || isCorrectNetwork === false}
                type="primary"
                size="large"
                onClick={async () => {
                  if (userSigner && poolContract) {
                    // eslint-disable-next-line
                    await tx(poolContract.claim(), (update: any) => {
                      if (update && (update.status === "confirmed" || update.status === 1)) {
                        buildAndSetAggregatePool(
                          userSigner,
                          poolContract,
                          writeContracts,
                          currentNetwork,
                          updateAggregatePool,
                          isCorrectNetwork,
                        ).then(() => {
                          Toast.claimSuccessNotification();
                        });
                      }
                    });
                  }
                }}
                style={{ width: "100%" }}
                block={true}
              >
                Claim {aggregatePool.currentInvestorInfo.unclaimedDividends} {aggregatePool.poolToken.symbol}
              </Button>
            </Col>
          </Row>
        </Layout>
      );
    }
  };

  // HACK HACK HACK
  let title = `Aggregate Pool: ${shortenAddress(aggregatePool.address)}`;
  let discordInviteLink = "https://discord.gg/sKmZjUJF3N";
  if (aggregatePool.address === "0x27A207686148667FfBCc3Ec0CD163BCe87E13892") {
    title = "Large Cap Aggregate Pool";
    discordInviteLink = "https://discord.gg/StN69RAHSg";
  } else if (aggregatePool.address === "0xEC00BD50cd7F5479D8A3626931593E0B26d92717") {
    title = "Small Cap Aggregate Pool";
    discordInviteLink = "https://discord.gg/23DqGETJmF";
  }
  return (
    <Layout>
      <Row align="middle">
        <Col md={{ span: 24 }} lg={{ span: 16, offset: 1 }} xl={{ span: 16, offset: 2 }} xxl={{ span: 16, offset: 3 }}>
          <AddressLink
            address={aggregatePool.address}
            text={
              <Typography.Text strong style={{ fontSize: "28px" }}>
                {title}
              </Typography.Text>
            }
          />
        </Col>
        <Col>
          <Button type="primary" href={discordInviteLink}>
            Join our Discord for updates!
          </Button>
        </Col>
      </Row>
      <Row>
        <Col
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 12, offset: 1 }}
          xl={{ span: 11, offset: 2 }}
          xxl={{ span: 10, offset: 3 }}
          style={{ paddingTop: "3vh" }}
        >
          <div className="og-gradient-border">
            <Card>
              <Row>
                <Col span={8}>
                  <Button
                    type="text"
                    size="large"
                    style={{
                      width: "100%",
                      backgroundColor: currentInfoTab === InfoTab.PoolStats ? "#150428" : "transparent",
                    }}
                    onClick={() => {
                      setCurrentInfoTab(InfoTab.PoolStats);
                    }}
                  >
                    Pool Stats
                  </Button>
                </Col>
                <Col span={8}>
                  <Button
                    type="text"
                    size="large"
                    style={{
                      width: "100%",
                      backgroundColor: currentInfoTab === InfoTab.InvestorInfo ? "#150428" : "transparent",
                    }}
                    onClick={() => {
                      setCurrentInfoTab(InfoTab.InvestorInfo);
                    }}
                  >
                    Investor Info
                  </Button>
                </Col>
                <Col span={8}>
                  <Button
                    type="text"
                    size="large"
                    style={{
                      width: "100%",
                      backgroundColor: currentInfoTab === InfoTab.IndividualPools ? "#150428" : "transparent",
                    }}
                    onClick={() => {
                      setCurrentInfoTab(InfoTab.IndividualPools);
                    }}
                  >
                    Individual Pools
                  </Button>
                </Col>
              </Row>
              <Row style={{ paddingTop: "5vh" }}>{infoTabContents()}</Row>
            </Card>
          </div>
        </Col>
        <Col
          sm={{ span: 20, push: 2 }}
          md={{ span: 20, push: 2 }}
          lg={{ span: 9, push: 0 }}
          style={{ paddingTop: "3vh", paddingLeft: "2em", paddingRight: "2em" }}
        >
          <div className="og-gradient-border" style={{ marginBottom: "1vh" }}>
            <Card>
              <Layout>
                <Row>
                  <Col span={8}>
                    <Typography.Text strong style={{ fontSize: "21px" }}>
                      {currentActionTab === ActionTab.Deposit ? "Deposit" : "Claim"}
                    </Typography.Text>
                  </Col>
                  <Col xs={{ push: 5 }} sm={{ push: 7 }} md={{ push: 10 }} lg={{ push: 6 }} xl={{ push: 9 }}>
                    <AuthStatusButton userSigner={userSigner} />
                  </Col>
                </Row>
                <Row style={{ marginTop: "5vh" }}>
                  <Col span={8}>
                    <Button
                      type="text"
                      size="large"
                      style={{
                        width: "100%",
                        backgroundColor: currentActionTab === ActionTab.Deposit ? "#150428" : "transparent",
                      }}
                      onClick={() => {
                        setCurrentActionTab(ActionTab.Deposit);
                      }}
                    >
                      Invest
                    </Button>
                  </Col>
                  <Col span={8}>
                    <Button
                      type="text"
                      size="large"
                      style={{
                        width: "100%",
                        backgroundColor: currentActionTab === ActionTab.Claim ? "#150428" : "transparent",
                      }}
                      onClick={() => {
                        setCurrentActionTab(ActionTab.Claim);
                      }}
                    >
                      Claim
                    </Button>
                  </Col>
                </Row>
                <Row style={{ marginTop: "5vh" }}>
                  <Col span={18}>
                    <Typography.Text style={{ fontSize: "15px" }}>
                      Balance: {aggregatePool.poolToken.userBalance} {aggregatePool.poolToken.symbol}
                    </Typography.Text>
                  </Col>
                </Row>
                <Row style={{ paddingTop: "5vh" }}>{actionTabContents()}</Row>
              </Layout>
            </Card>
          </div>
        </Col>
      </Row>
    </Layout>
  );
};

export default InvestPoolHome;
