import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, Modal, Row, Tooltip, Typography } from "antd";
import { BigNumber, Contract } from "ethers";
import { useState } from "react";
import { useEthersContext } from "../contexts/ethersContext";
import { PrecisionConverter } from "../helpers/PrecisionConverter";
import { buildAndSetIndividualPool, roundDecimal } from "../helpers/utils";
import { IndividualPool } from "../types/types";
import * as Toast from "../helpers/toasts";
import ApproveButton from "./ApproveButton";
import InputERC20 from "./InputERC20";
import { useAccount, useBalance } from "wagmi";
const { confirm } = Modal;

type ContributeFormProps = {
  poolContract: Contract | undefined;
  individualPool: IndividualPool;
  setIndividualPool: (individualPool: IndividualPool) => void;
  isRecipient: boolean;
};

const ContributeForm = ({
  poolContract,
  individualPool,
  setIndividualPool,
  isRecipient,
}: ContributeFormProps): JSX.Element => {
  const { address } = useAccount();

  const { data: balanceData } = useBalance({
    addressOrName: address,
    token: individualPool.poolToken.address,
  });

  const [form] = Form.useForm();
  const [contributeInput, setContributeInput] = useState<string>("0");
  const { writeContracts, tx, currentNetwork, userSigner, isCorrectNetwork } = useEthersContext();
  const precisionConverter = new PrecisionConverter(individualPool.poolToken.decimal);
  const contributeConfirmation = () => {
    const message = (
      <>
        Contribution: {contributeInput} {individualPool.poolToken.symbol}
        <br />
        {!!individualPool.protocolTakeRate && (
          <>
            Fees: {roundDecimal(parseFloat(contributeInput) * individualPool.protocolTakeRate)}{" "}
            {individualPool.poolToken.symbol}
            <br />
            Contribution after fees: {roundDecimal(
              parseFloat(contributeInput) * (1 - individualPool.protocolTakeRate),
            )}{" "}
            {individualPool.poolToken.symbol}
            <br />
          </>
        )}
      </>
    );
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: message,
      onOk: async () => {
        if (userSigner && poolContract) {
          const currentUserAddress = await userSigner.getAddress();
          if (
            parseFloat(contributeInput) >
            precisionConverter.fromContractAmount(
              await individualPool.poolToken.contract?.balanceOf(currentUserAddress),
            )
          ) {
            Toast.balanceFailureNotification(individualPool.poolToken.symbol);
          } else {
            // eslint-disable-next-line
            await tx(poolContract.contribute(precisionConverter.toContractAmount(contributeInput)), (update: any) => {
              if (update && (update.status === "confirmed" || update.status === 1)) {
                buildAndSetIndividualPool(
                  userSigner,
                  poolContract,
                  writeContracts["ProtocolConfig"],
                  currentNetwork,
                  setIndividualPool,
                  isCorrectNetwork,
                ).then(() => {
                  Toast.contributeSuccessNotification(contributeInput, individualPool.poolToken.symbol);
                });
              }
            });
          }
        } else {
          Toast.showError(
            "Contribute failed. Try refreshing the page, and please message #support in Discord if this issue persists!",
          );
        }
      },
    });
  };

  const allowance =
    balanceData !== undefined ? precisionConverter.fromContractAmount(BigNumber.from(balanceData.value.toString())) : 0;

  return (
    <Form form={form} layout="vertical" onFinish={contributeConfirmation}>
      <Row>
        <Col span={24}>
          <InputERC20
            name="contribute"
            rules={[
              { type: "number", required: true, message: "" },
              { min: 0.0000000000000000001, message: "Must be more than 0", type: "number" },
            ]}
            pool={individualPool}
            setInput={setContributeInput}
          />
        </Col>
      </Row>
      {individualPool.protocolTakeRate > 0 && (
        <Row style={{ paddingBottom: "2em" }}>
          <Col span={8}>
            <Typography.Text style={{ fontSize: "15px", color: "#B7B7B7" }}>Protocol Take Rate</Typography.Text>
          </Col>
          <Col span={16}>
            <Typography.Text style={{ fontSize: "15px", color: "#B7B7B7", float: "right" }}>
              {!contributeInput ? 0 : roundDecimal(parseInt(contributeInput) * individualPool.protocolTakeRate, 2)}{" "}
              {individualPool.poolToken.symbol} ({individualPool.protocolTakeRate * 100}%)
            </Typography.Text>
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          {allowance === 0 ? (
            <ApproveButton pool={individualPool} poolContract={poolContract} setPool={setIndividualPool} />
          ) : (
            <Form.Item shouldUpdate>
              {() => (
                <Tooltip
                  title={
                    !individualPool.totalDeployed && (
                      <Typography.Text>You must withdraw capital before contributing</Typography.Text>
                    )
                  }
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={
                      !isRecipient ||
                      !tx ||
                      !form.isFieldsTouched(true) ||
                      !!form.getFieldsError().filter(({ errors }) => errors.length).length ||
                      !individualPool.totalDeployed ||
                      isCorrectNetwork === false
                    }
                    size="large"
                    block={true}
                    style={{ width: "100%", height: "100%" }}
                  >
                    Contribute
                  </Button>
                </Tooltip>
              )}
            </Form.Item>
          )}
        </Col>
      </Row>
    </Form>
  );
};

export default ContributeForm;
