import { Card, Row, Typography } from "antd";
import { roundDecimal } from "../helpers/utils";
import { AggregatePool, IndividualPool } from "../types/types";

const AnnualYieldCard = ({ pool }: { pool: IndividualPool | AggregatePool }): JSX.Element => {
  return (
    <Card size="small" style={{ backgroundColor: "#150428" }}>
      <Row style={{ paddingTop: "1vh" }}>
        <Typography.Text style={{ fontSize: "15px" }}>Annual Yield</Typography.Text>
      </Row>
      <Row style={{ paddingTop: "0.5vh" }}>
        <Typography.Text style={{ fontSize: "15px" }}>{roundDecimal(pool.annualYield, 1)}%</Typography.Text>
      </Row>
      <Row style={{ paddingTop: "1vh" }}>
        <Typography.Text style={{ fontSize: "15px" }}>Total Contributed</Typography.Text>
      </Row>
      <Row style={{ paddingTop: "0.5vh" }}>
        <Typography.Text style={{ fontSize: "15px" }}>
          {roundDecimal(pool.cumulativeDividends, 0)} {pool.poolToken.symbol}
        </Typography.Text>
      </Row>
    </Card>
  );
};

export default AnnualYieldCard;
