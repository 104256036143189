import { Alert, Button, Card, Col, Layout, Row, Space, Spin, Typography } from "antd";
import { useContext, useEffect, useState } from "react";
import AuthStatusButton from "../components/AuthStatusButton";
import CapitalCard from "../components/CapitalCard";
import ContributeForm from "../components/ContributeForm";
import AnnualYieldCard from "../components/AnnualYieldCard";
import WithdrawableBalanceCard from "../components/WithdrawableBalanceCard";
import WithdrawForm from "../components/WithdrawForm";
import { useEthersContext } from "../contexts/ethersContext";
import { isBasePoolContract } from "../helpers/typeGuards";
import { buildAndSetIndividualPool, findPool, roundDecimal, shortenAddress } from "../helpers/utils";
import { ActionTab, IndividualPool, InfoTab } from "../types/types";
import AddressLink from "../components/AddressLink";
import { EMPTY_INDIVIDUAL_POOL } from "../helpers/emptyPools";
import { StoreContext } from "../providers/store";
import { BigNumber, Contract } from "ethers";
import { ArrowLeftOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { useAccount, useBalance } from "wagmi";
import { PrecisionConverter } from "../helpers/PrecisionConverter";

type ReceivePoolHomeProps = {
  poolAddress: string;
};

const ReceivePoolHome = (): JSX.Element => {
  const { poolAddress } = useParams() as ReceivePoolHomeProps;
  const { userSigner, writeContracts, readContracts, currentNetwork, isCorrectNetwork } = useEthersContext();
  const { individualPools, updateIndividualPool } = useContext(StoreContext);

  const [loading, setLoading] = useState(true);
  const [individualPool, setIndividualPool] = useState<IndividualPool>(EMPTY_INDIVIDUAL_POOL);
  const [isRecipient, setIsRecipient] = useState(false);
  const [currentInfoTab, setCurrentInfoTab] = useState<InfoTab>(InfoTab.PoolStats);
  const [currentActionTab, setCurrentActionTab] = useState<ActionTab>(ActionTab.Withdraw);
  const [poolContract, setPoolContract] = useState<Contract | undefined>(
    findPool(poolAddress, writeContracts, isBasePoolContract),
  );

  const { address } = useAccount();

  const { data: balanceData } = useBalance({
    addressOrName: address,
    token: individualPool.poolToken.address,
  });

  useEffect(() => {
    (async () => {
      setIsRecipient((await userSigner?.getAddress()) === individualPool.recipientAddress);
    })();
  }, [userSigner, individualPool]);

  useEffect(() => {
    if (typeof individualPools[poolAddress] !== "undefined") {
      setIndividualPool(individualPools[poolAddress]);
      setLoading(false);
    } else {
      const allContracts = !userSigner || isCorrectNetwork === false ? readContracts : writeContracts;
      // we are guaranteed to have contracts be of length at least 0, so continue to keep loading state
      if (Object.keys(allContracts).length === 0) {
        return;
      }
      const newPoolContract = findPool(poolAddress, allContracts, isBasePoolContract);
      setPoolContract(newPoolContract);

      if (newPoolContract) {
        buildAndSetIndividualPool(
          userSigner,
          newPoolContract,
          allContracts["ProtocolConfig"],
          currentNetwork,
          pool => {
            updateIndividualPool(pool);
            setIndividualPool(pool);
            setLoading(false);
          },
          isCorrectNetwork,
        );
      } else {
        setLoading(false);
      }
    }
  }, [userSigner, readContracts, writeContracts, isRecipient]);

  if (loading) {
    return <Spin />;
  }

  if (!poolContract || !isRecipient) {
    return (
      <Layout>
        <Row>
          <Col
            md={{ span: 24 }}
            lg={{ span: 16, offset: 1 }}
            xl={{ span: 16, offset: 2 }}
            xxl={{ span: 16, offset: 3 }}
          >
            <Space direction="vertical">
              <Typography.Text strong style={{ fontSize: "28px" }}>
                {!poolContract ? "Individual pool doesn't exist" : "You are not a recipient in this pool"}
              </Typography.Text>
              <Typography.Link href="/receive">
                <Space>
                  <ArrowLeftOutlined />
                  Click here to return to the receive page
                </Space>
              </Typography.Link>
            </Space>
          </Col>
        </Row>
      </Layout>
    );
  }

  const precisionConverter = new PrecisionConverter(individualPool.poolToken.decimal);

  const allowance =
    balanceData !== undefined ? precisionConverter.fromContractAmount(BigNumber.from(balanceData.value.toString())) : 0;

  const infoTabContents = () => {
    if (currentInfoTab === InfoTab.PoolStats) {
      return (
        <Layout>
          <Row style={{ textAlign: "center" }}>
            <Col span={12} style={{ paddingRight: "0.5vw" }}>
              <CapitalCard
                undeployedCapital={roundDecimal(individualPool.totalUndeployed)}
                deployedCapital={roundDecimal(individualPool.totalDeployed)}
                symbol={individualPool.poolToken.symbol}
              />
            </Col>
            <Col span={12} style={{ paddingLeft: "0.5vw" }}>
              <AnnualYieldCard pool={individualPool} />
            </Col>
          </Row>
        </Layout>
      );
    } else if (currentInfoTab === InfoTab.RecipientInfo) {
      return (
        <Layout>
          <Row style={{ textAlign: "center" }}>
            <Col span={24}>
              <WithdrawableBalanceCard individualPool={individualPool} />
            </Col>
          </Row>
        </Layout>
      );
    }
  };

  const actionTabContents = () => {
    if (currentActionTab === ActionTab.Withdraw) {
      return (
        <Layout>
          <Row>
            <Col span={24}>
              <WithdrawForm
                poolContract={poolContract}
                individualPool={individualPool}
                setIndividualPool={updateIndividualPool}
                isRecipient={isRecipient}
              />
            </Col>
          </Row>
        </Layout>
      );
    } else if (currentActionTab === ActionTab.Contribute) {
      return (
        <Layout>
          <Row>
            <Col span={24}>
              <ContributeForm
                poolContract={poolContract}
                individualPool={individualPool}
                setIndividualPool={updateIndividualPool}
                isRecipient={isRecipient}
              />
            </Col>
          </Row>
        </Layout>
      );
    }
  };

  return (
    <>
      {!isRecipient && (
        <Alert
          message={
            <Space>
              <ExclamationCircleOutlined />
              <Typography.Text style={{ fontSize: "14px", color: "white" }}>
                You are not a recipient of this pool.
              </Typography.Text>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSe9B8iwP5fy4nXd3heArbqV0nF3DX6TA0Hkz-3v63KNe2xypg/viewform"
                style={{ fontSize: "14px", color: "white", textDecoration: "underline" }}
              >
                Click here to sign up your guild!
              </a>
            </Space>
          }
          showIcon={false}
          style={{ marginTop: 1, backgroundColor: "inherit", textAlign: "center", padding: ".5em" }}
          banner
        />
      )}
      <Layout style={{ padding: "2em 2em" }}>
        <Row>
          <Col
            md={{ span: 24 }}
            lg={{ span: 16, offset: 1 }}
            xl={{ span: 16, offset: 2 }}
            xxl={{ span: 16, offset: 3 }}
          >
            <AddressLink
              address={individualPool.address}
              text={
                <Typography.Text strong style={{ fontSize: "28px" }}>{`Individual Pool: ${shortenAddress(
                  individualPool.address,
                )}`}</Typography.Text>
              }
            />
          </Col>
        </Row>
        <Row>
          <Col
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 11 }}
            xl={{ span: 10, offset: 1 }}
            xxl={{ span: 10, offset: 3 }}
            style={{ paddingTop: "3vh" }}
          >
            <div className="og-gradient-border">
              <Card>
                <Row>
                  <Col span={12}>
                    <Button
                      type="text"
                      size="large"
                      style={{
                        width: "100%",
                        backgroundColor: currentInfoTab === InfoTab.PoolStats ? "#150428" : "transparent",
                      }}
                      onClick={() => {
                        setCurrentInfoTab(InfoTab.PoolStats);
                      }}
                    >
                      Pool Stats
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button
                      type="text"
                      size="large"
                      style={{
                        width: "100%",
                        backgroundColor: currentInfoTab === InfoTab.RecipientInfo ? "#150428" : "transparent",
                      }}
                      onClick={() => {
                        setCurrentInfoTab(InfoTab.RecipientInfo);
                      }}
                    >
                      Recipient Info
                    </Button>
                  </Col>
                </Row>
                <Row style={{ paddingTop: "5vh" }}>{infoTabContents()}</Row>
              </Card>
            </div>
          </Col>
          <Col
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 12, push: 0 }}
            xl={{ span: 11, push: 0 }}
            style={{ paddingTop: "3vh", paddingLeft: "2em", paddingRight: "2em" }}
          >
            <div className="og-gradient-border">
              <Card>
                <Row>
                  <Col span={13}>
                    <Typography.Text strong style={{ fontSize: "21px" }}>
                      {currentActionTab === ActionTab.Withdraw ? "Withdraw" : "Contribute"}
                    </Typography.Text>
                  </Col>
                  <Col xs={{ push: 0 }} sm={{ push: 2 }} md={{ push: 5 }} lg={{ push: 1 }} xl={{ push: 4 }}>
                    <AuthStatusButton userSigner={userSigner} />
                  </Col>
                </Row>
                <Row style={{ marginTop: "5vh" }}>
                  <Col span={8}>
                    <Button
                      type="text"
                      size="large"
                      style={{
                        width: "100%",
                        backgroundColor: currentActionTab === ActionTab.Withdraw ? "#150428" : "transparent",
                      }}
                      onClick={() => {
                        setCurrentActionTab(ActionTab.Withdraw);
                      }}
                    >
                      Withdraw
                    </Button>
                  </Col>
                  <Col span={8}>
                    <Button
                      type="text"
                      size="large"
                      style={{
                        width: "100%",
                        backgroundColor: currentActionTab === ActionTab.Contribute ? "#150428" : "transparent",
                      }}
                      onClick={() => {
                        setCurrentActionTab(ActionTab.Contribute);
                      }}
                    >
                      Contribute
                    </Button>
                  </Col>
                </Row>
                <Row style={{ marginTop: "5vh" }}>
                  <Col offset={6} span={18}>
                    <Typography.Text style={{ fontSize: "15px", float: "right", paddingRight: "1.7vw" }}>
                      Balance: {allowance} {individualPool.poolToken.symbol}
                    </Typography.Text>
                  </Col>
                </Row>
                <Row style={{ paddingTop: "5vh" }}>{actionTabContents()}</Row>
              </Card>
            </div>
          </Col>
        </Row>
      </Layout>
    </>
  );
};

export default ReceivePoolHome;
