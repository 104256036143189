import React, { useState } from "react";
import { Button, Modal, Form, Col, Row } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
const { confirm } = Modal;
import { Contract } from "ethers";
import { PrecisionConverter } from "../helpers/PrecisionConverter";
import { IndividualPool } from "../types/types";
import { useEthersContext } from "../contexts/ethersContext";
import * as Toast from "../helpers/toasts";
import { buildAndSetIndividualPool } from "../helpers/utils";
import InputERC20 from "./InputERC20";

type WithdrawFormProps = {
  poolContract: Contract | undefined;
  individualPool: IndividualPool;
  setIndividualPool: (individualPool: IndividualPool) => void;
  isRecipient: boolean;
};

const WithdrawForm = ({
  poolContract,
  individualPool,
  setIndividualPool,
  isRecipient,
}: WithdrawFormProps): JSX.Element => {
  const [form] = Form.useForm();
  const [withdrawInput, setWithdrawInput] = useState<string>("0");

  const { writeContracts, tx, currentNetwork, userSigner, isCorrectNetwork } = useEthersContext();
  const precisionConverter = new PrecisionConverter(individualPool.poolToken.decimal);
  const withdrawConfirmation = () => {
    const message = `By clicking OK, you are agreeing to withdraw ${withdrawInput} ${individualPool.poolToken.symbol}.`;
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: message,
      onOk: async () => {
        if (userSigner && poolContract) {
          const currentUserAddress = await userSigner.getAddress();
          if (individualPool.recipientAddress === currentUserAddress) {
            // eslint-disable-next-line
            await tx(poolContract.withdraw(precisionConverter.toContractAmount(withdrawInput)), (update: any) => {
              if (update && (update.status === "confirmed" || update.status === 1)) {
                buildAndSetIndividualPool(
                  userSigner,
                  poolContract,
                  writeContracts["ProtocolConfig"],
                  currentNetwork,
                  setIndividualPool,
                  isCorrectNetwork,
                ).then(() => {
                  Toast.withdrawSuccessNotification(withdrawInput, individualPool.poolToken.symbol);
                });
              }
            });
          }
        } else {
          Toast.showError(
            "Withdraw failed. Try refreshing the page, and please message #support in Discord if this issue persists!",
          );
        }
      },
    });
  };

  return (
    <Form form={form} onFinish={withdrawConfirmation} layout="vertical">
      <Row>
        <Col span={24}>
          <InputERC20
            name="withdraw"
            rules={[
              { type: "number", required: true, message: "" },
              { min: 0.0000000000000000001, message: "Must be more than 0", type: "number" },
              {
                max:
                  individualPool.totalUndeployed > individualPool.recipientBalance
                    ? individualPool.recipientBalance
                    : individualPool.totalUndeployed,
                message:
                  individualPool.totalUndeployed > individualPool.recipientBalance
                    ? "Must be less than withdrawable balance"
                    : "Must be less than total undeployed capital",
                type: "number",
              },
            ]}
            pool={individualPool}
            setInput={setWithdrawInput}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item shouldUpdate>
            {() => (
              <Button
                type="primary"
                htmlType="submit"
                disabled={
                  !isRecipient ||
                  !tx ||
                  !form.isFieldsTouched(true) ||
                  isCorrectNetwork === false ||
                  !!form.getFieldsError().filter(({ errors }) => errors.length).length
                }
                size="large"
                block={true}
                style={{ width: "100%", height: "100%" }}
              >
                Withdraw
              </Button>
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default WithdrawForm;
