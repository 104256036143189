import { Button, Space, Layout, Dropdown, Menu } from "antd";
import { Badge, Typography } from "@openguild/design-system";
import "antd/dist/antd.css";
import { useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import { ADDITIONAL_WAGMI_CHAINS, AVAILABLE_CHAINS, AVAILABLE_NETWORKS, NETWORKS } from "./Constants";
import { Transactor } from "./helpers/Transactor";
import { useContractLoader, useGasPrice } from "eth-hooks";
import { useContractConfig } from "./hooks";
import HeaderMenuButton from "./components/HeaderMenuButton";
import Faucet from "./components/Faucet";
import Footer from "./components/Footer";
import Address from "./components/Address";
import AuthModal from "./components/AuthModal";
import InvestPoolHome from "./views/InvestPoolHome";
import ReceivePoolHome from "./views/ReceivePoolHome";
import ManagePoolHome from "./views/ManagePoolHome";
import InvestPoolsHome from "./views/InvestPoolsHome";
import ReceivePoolsHome from "./views/ReceivePoolsHome";
import ManagePoolsHome from "./views/ManagePoolsHome";
import { EthersContext } from "./contexts/ethersContext";
import { Store } from "./providers/store";
import { POOL_MANAGERS } from "./Constants";
import { Network } from "./types/types";
import { allChains, useAccount, useDisconnect, useNetwork, useProvider, useSigner, useSwitchNetwork } from "wagmi";
import { TEthersProviderOrSigner } from "eth-hooks/models";
import { StaticJsonRpcProvider } from "@ethersproject/providers";
import NetworkDisplay from "./components/NetworkDisplay";

const App = (): JSX.Element => {
  const [currentNetwork, setCurrentNetwork] = useState<Network>(
    //@ts-ignore
    JSON.parse(window.localStorage.getItem("network")) ||
      (process.env.REACT_APP_NETWORK
        ? NETWORKS[process.env.REACT_APP_NETWORK as keyof typeof NETWORKS]
        : NETWORKS.localhost),
  );

  const [providerChainId, setProviderChainId] = useState<number>(
    //@ts-ignore
    JSON.parse(window.localStorage.getItem("providerChainId")) ||
      (!process.env.REACT_APP_CHAIN_ID ? 137 : process.env.REACT_APP_CHAIN_ID),
  );

  const { chain: activeChain } = useNetwork();
  const { isLoading, pendingChainId, switchNetwork } = useSwitchNetwork();

  const { address } = useAccount();
  const { data: userSigner } = useSigner();

  const clientChainId = !activeChain ? providerChainId : activeChain.id;
  const localProvider = useProvider({ chainId: clientChainId });
  const { disconnect } = useDisconnect();

  const contractConfig = useContractConfig();

  /* 🔥 This hook will get the price of Gas from ⛽️ EtherGasStation */
  const gasPrice = useGasPrice(currentNetwork, "average");

  // You can warn the user if you would like them to be on a specific network

  const userChainId =
    //@ts-ignore
    userSigner && userSigner.provider && userSigner.provider._network && userSigner.provider._network.chainId;
  const isCorrectNetwork = clientChainId && userChainId && clientChainId === userChainId;

  // The transactor wraps transactions and provides notificiations
  const tx = Transactor(userSigner || localProvider, gasPrice);
  // Faucet Tx can be used to send funds from the faucet
  const faucetTx = Transactor(localProvider, gasPrice);

  // Load in your local 📝 contract and read a value from it:
  const readContracts = useContractLoader(localProvider as TEthersProviderOrSigner, contractConfig, clientChainId);

  // If you want to make 🔐 write transactions to your contracts, use the userSigner:
  const writeContracts = useContractLoader(userSigner as TEthersProviderOrSigner, contractConfig, clientChainId);

  // eslint-disable-next-line
  const handleMenuClick = (e: any) => {
    const network: Network = NETWORKS[e.key as keyof typeof NETWORKS];
    window.localStorage.setItem("network", JSON.stringify(network));
    setCurrentNetwork(network);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {AVAILABLE_NETWORKS.map(result => {
        const network = NETWORKS[result as keyof typeof NETWORKS];
        return (
          <Menu.Item key={result}>
            <Badge
              color={network.color}
              text={<Typography.Text style={{ fontSize: "16px" }}>{network.name}</Typography.Text>}
            />
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const chains = [...allChains, ...ADDITIONAL_WAGMI_CHAINS];

  const wagmiMenu = (
    <>
      <Menu>
        {chains
          .filter(chain => AVAILABLE_CHAINS.includes(chain.id))
          .map(chain => {
            return (
              <Menu.Item
                disabled={(activeChain && !switchNetwork) || chain.id === activeChain?.id}
                key={chain.id}
                onClick={() => {
                  window.localStorage.setItem("providerChainId", JSON.stringify(chain.id));
                  setProviderChainId(chain.id);
                  switchNetwork?.(chain.id);
                }}
              >
                <Typography.Text>{chain.name}</Typography.Text>
              </Menu.Item>
            );
          })}
      </Menu>
    </>
  );

  return (
    <EthersContext.Provider
      value={{
        //@ts-ignore
        userSigner,
        writeContracts,
        readContracts,
        currentNetwork,
        isCorrectNetwork,
        clientChainId,
        tx,
      }}
    >
      <Store>
        <Layout className="App" style={{ minHeight: "100vh" }}>
          <Layout.Header
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid rgba(127, 15, 255, 0.4)",
            }}
          >
            <Space>
              <a>
                <img
                  onClick={() => {
                    window.location.pathname = "/invest";
                  }}
                  src={`${process.env.PUBLIC_URL}/header-logo.png`}
                  style={{
                    height: 40,
                    imageRendering: "crisp-edges",
                  }}
                  alt="OpenGuild Logo"
                />
              </a>
            </Space>
            <Space direction="horizontal">
              {!window.location.pathname.startsWith("/game") && (
                <>
                  {" "}
                  <HeaderMenuButton
                    onClick={() => {
                      window.location.pathname = "/invest";
                    }}
                    text="Deposit"
                  />
                  <HeaderMenuButton
                    onClick={() => {
                      window.location.pathname = "/receive";
                    }}
                    text="Receive"
                  />
                </>
              )}
              {POOL_MANAGERS.includes(address || "") && !window.location.pathname.startsWith("/game") && (
                <HeaderMenuButton
                  onClick={() => {
                    window.location.pathname = "/manage";
                  }}
                  text="Manage"
                />
              )}
            </Space>
            <Space style={{ float: "right" }}>
              {address && (
                <Faucet
                  // @ts-ignore
                  localProvider={localProvider as StaticJsonRpcProvider}
                  faucetTx={faucetTx}
                  address={address || ""}
                />
              )}

              {window.location.pathname.startsWith("/game") && (
                <Dropdown overlay={wagmiMenu}>
                  <Button size="large">
                    {isLoading && pendingChainId !== activeChain?.id ? (
                      <Typography.Text style={{ fontSize: "16.5px" }}>
                        Switching to {chains.find(chain => chain.id === pendingChainId)?.name}
                      </Typography.Text>
                    ) : !activeChain ? (
                      <Typography.Text style={{ fontSize: "16.5px" }}>
                        {chains.find(chain => chain.id === providerChainId)?.name}
                      </Typography.Text>
                    ) : (
                      <Typography.Text style={{ fontSize: "16.5px" }}>{activeChain?.name}</Typography.Text>
                    )}
                  </Button>
                </Dropdown>
              )}

              {clientChainId && userChainId && clientChainId !== userChainId && (
                <NetworkDisplay clientChainId={clientChainId} />
              )}

              {!window.location.pathname.startsWith("/game") && (
                <Dropdown overlay={menu}>
                  <Button size="large">
                    <Badge color={currentNetwork.color} />
                    <Typography.Text style={{ fontSize: "16px" }}>{currentNetwork.name}</Typography.Text>
                  </Button>
                </Dropdown>
              )}

              {address ? (
                <Address
                  address={address || ""}
                  blockExplorer={
                    !activeChain
                      ? chains.find(chain => chain.id === providerChainId)?.blockExplorers?.default.url
                      : activeChain.blockExplorers?.default.url
                  }
                  ensProvider={null}
                  logoutOfWeb3Modal={disconnect}
                />
              ) : (
                <AuthModal />
              )}
            </Space>
          </Layout.Header>
          <Layout.Content style={{ padding: "4em 10em" }}>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Navigate to="/invest" replace />} />
                <Route path="/invest" element={<InvestPoolsHome />} />
                <Route path="/receive" element={<ReceivePoolsHome />} />
                <Route path="/manage" element={<ManagePoolsHome />} />
                <Route path="/invest/pool/:poolAddress" element={<InvestPoolHome />} />
                <Route path="/receive/pool/:poolAddress" element={<ReceivePoolHome />} />
                <Route path="/manage/pool/:poolAddress" element={<ManagePoolHome />} />
              </Routes>
            </BrowserRouter>
          </Layout.Content>
          <Layout.Footer>
            <Footer />
          </Layout.Footer>
        </Layout>
      </Store>
    </EthersContext.Provider>
  );
};

export default App;
