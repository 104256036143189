import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Tooltip, Typography } from "antd";
import { Contract } from "ethers";
import React from "react";
import { PrecisionConverter } from "../helpers/PrecisionConverter";
import { AggregatePool, IndividualPool, PoolTypeEnum } from "../types/types";
import { useEthersContext } from "../contexts/ethersContext";
import * as Toast from "../helpers/toasts";
import { buildAndSetAggregatePool, buildAndSetIndividualPool } from "../helpers/utils";
import { erc20ABI, useContract } from "wagmi";
const { confirm } = Modal;

type ApproveButtonProps = {
  pool: IndividualPool | AggregatePool;
  poolContract: Contract | undefined;
  setPool: ((aggregatePool: AggregatePool) => void) | ((individualPool: IndividualPool) => void);
};

const ApproveButton = ({ pool, poolContract, setPool }: ApproveButtonProps): JSX.Element => {
  const { writeContracts, tx, currentNetwork, isCorrectNetwork, userSigner } = useEthersContext();
  const precisionConverter = new PrecisionConverter(pool.poolToken.decimal);

  const poolTokenContract = useContract({
    addressOrName: pool.poolToken.address,
    contractInterface: erc20ABI,
    signerOrProvider: userSigner,
  });

  const approveConfirmation = () => {
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: `By clicking OK, you are agreeing to approve contribution of ${pool.poolToken.symbol} on your behalf`,
      onOk: async () => {
        if (userSigner && poolContract) {
          await tx(
            poolTokenContract.approve(
              pool.address,
              precisionConverter.toContractAmount((Number.MAX_SAFE_INTEGER - 1).toString()),
            ),
            // eslint-disable-next-line
            (update: any) => {
              if (update && (update.status === "confirmed" || update.status === 1)) {
                if (pool.type == PoolTypeEnum.Aggregate) {
                  const setAggregatePool = setPool as React.Dispatch<React.SetStateAction<AggregatePool>>;
                  buildAndSetAggregatePool(
                    userSigner,
                    poolContract,
                    writeContracts,
                    currentNetwork,
                    setAggregatePool,
                    isCorrectNetwork,
                  ).then(() => {
                    Toast.approveSuccessNotification(pool.poolToken.symbol);
                  });
                } else if (pool.type == PoolTypeEnum.Individual) {
                  const setIndividualPool = setPool as (individualPool: IndividualPool) => void;
                  buildAndSetIndividualPool(
                    userSigner,
                    poolContract,
                    writeContracts["ProtocolConfig"],
                    currentNetwork,
                    setIndividualPool,
                    isCorrectNetwork,
                  ).then(() => {
                    Toast.approveSuccessNotification(pool.poolToken.symbol);
                  });
                }
              }
            },
          );
        } else {
          Toast.showError(
            "Approve failed. Try refreshing the page, and please message #support in Discord if this issue persists!",
          );
        }
      },
    });
  };

  return (
    <Tooltip title={!userSigner && <Typography.Text>You must be logged in to approve your capital</Typography.Text>}>
      <Button
        type="primary"
        onClick={approveConfirmation}
        size="large"
        block={true}
        style={{ height: "100%" }}
        disabled={!userSigner || !tx || isCorrectNetwork === false}
      >
        Approve
      </Button>
    </Tooltip>
  );
};

export default ApproveButton;
