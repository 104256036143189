import { Contract, Signer } from "ethers";
import { createContext, useContext } from "react";
import { Network } from "../types/types";

export const EthersContext = createContext<{
  userSigner?: Signer;
  readContracts: Record<string, Contract>;
  writeContracts: Record<string, Contract>;
  currentNetwork: Network;
  isCorrectNetwork: boolean;
  clientChainId: number;
  // eslint-disable-next-line
  tx?: any;
}>({
  userSigner: undefined,
  readContracts: {},
  writeContracts: {},
  currentNetwork: null as unknown as Network,
  isCorrectNetwork: false,
  clientChainId: 0,
  tx: undefined,
});

export const useEthersContext = (): {
  userSigner?: Signer | undefined;
  readContracts: Record<string, Contract>;
  writeContracts: Record<string, Contract>;
  currentNetwork: Network;
  isCorrectNetwork: boolean;
  clientChainId: number;
  // eslint-disable-next-line
  tx?: any;
} => {
  const context = useContext(EthersContext);
  if (context === undefined) {
    throw new Error("useEthersContext must be used within a EthersContext provider");
  }
  return context;
};
