import { createContext, useReducer } from "react";

import { IndividualPool, AggregatePool } from "../types/types";

/* eslint-disable */
const initialState = {
  aggregatePools: {},
  individualPools: {},
  setAllAggregatePools: (aggregatePools: ReadonlyArray<AggregatePool>) => {},
  updateAggregatePool: (aggregatePool: AggregatePool) => {},
  setAllIndividualPools: (individualPools: ReadonlyArray<IndividualPool>) => {},
  updateIndividualPool: (individualPool: IndividualPool) => {},
};
/* eslint-enable */

export const StoreContext = createContext<StoreState>(initialState);

enum StoreActionType {
  SetAllAggregatePools,
  UpdateAggregatePool,
  SetAllIndividualPools,
  UpdateIndividualPool,
}

interface StoreState {
  aggregatePools: { [poolAddress: string]: AggregatePool };
  individualPools: { [poolAddress: string]: IndividualPool };
  setAllAggregatePools: (aggregatePools: ReadonlyArray<AggregatePool>) => void;
  updateAggregatePool: (aggregatePool: AggregatePool) => void;
  setAllIndividualPools: (individualPools: ReadonlyArray<IndividualPool>) => void;
  updateIndividualPool: (individualPool: IndividualPool) => void;
}

type StoreActions =
  | UpdateAggregatePoolStoreAction
  | SetAllAggregatePoolsStoreAction
  | UpdateIndividualPoolAction
  | SetAllIndividualPoolsStoreAction;

type UpdateAggregatePoolStoreAction = {
  type: StoreActionType.UpdateAggregatePool;
  payload: { aggregatePool: AggregatePool };
};

type SetAllAggregatePoolsStoreAction = {
  type: StoreActionType.SetAllAggregatePools;
  payload: { aggregatePools: ReadonlyArray<AggregatePool> };
};

type UpdateIndividualPoolAction = {
  type: StoreActionType.UpdateIndividualPool;
  payload: { individualPool: IndividualPool };
};

type SetAllIndividualPoolsStoreAction = {
  type: StoreActionType.SetAllIndividualPools;
  payload: { individualPools: ReadonlyArray<IndividualPool> };
};

const reducer = (state: StoreState, action: StoreActions): StoreState => {
  switch (action.type) {
    case StoreActionType.SetAllAggregatePools:
      return {
        ...state,
        aggregatePools: action.payload.aggregatePools.reduce((acc, pool) => ({ ...acc, [pool.address]: pool }), {}),
      };
    case StoreActionType.UpdateAggregatePool:
      return {
        ...state,
        aggregatePools: {
          ...state.aggregatePools,
          [action.payload.aggregatePool.address]: action.payload.aggregatePool,
        },
      };
    case StoreActionType.SetAllIndividualPools:
      return {
        ...state,
        individualPools: action.payload.individualPools.reduce((acc, pool) => ({ ...acc, [pool.address]: pool }), {}),
      };
    case StoreActionType.UpdateIndividualPool:
      return {
        ...state,
        individualPools: {
          ...state.individualPools,
          [action.payload.individualPool.address]: action.payload.individualPool,
        },
      };
    default:
      return state;
  }
};

export const Store = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = {
    aggregatePools: state.aggregatePools,
    individualPools: state.individualPools,
    setAllAggregatePools: (aggregatePools: ReadonlyArray<AggregatePool>) => {
      dispatch({ type: StoreActionType.SetAllAggregatePools, payload: { aggregatePools } });
    },
    updateAggregatePool: (aggregatePool: AggregatePool) => {
      dispatch({ type: StoreActionType.UpdateAggregatePool, payload: { aggregatePool } });
    },
    setAllIndividualPools: (individualPools: ReadonlyArray<IndividualPool>) => {
      dispatch({ type: StoreActionType.SetAllIndividualPools, payload: { individualPools } });
    },
    updateIndividualPool: (individualPool: IndividualPool) => {
      dispatch({ type: StoreActionType.UpdateIndividualPool, payload: { individualPool } });
    },
  };

  return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>;
};
