import { MediumOutlined, TwitterOutlined } from "@ant-design/icons";
import { Col, Row, Space, Typography } from "antd";

const Footer = (): JSX.Element => {
  return (
    <Space
      direction="horizontal"
      style={{ height: 200, display: "flex", justifyContent: "space-between" }}
      align="start"
    >
      <Space>
        <img
          onClick={() => {
            window.location.pathname = "/invest";
          }}
          src={`${process.env.PUBLIC_URL}/logo-128-transparent.png`}
          style={{
            height: 40,
            imageRendering: "crisp-edges",
          }}
          alt="React Logo"
        />
        {`© ${new Date().getFullYear()} OpenGuild. All rights reserved.`}
      </Space>

      <Row style={{ width: 400 }}>
        <Col span={8}>
          <Space direction="vertical">
            <Typography.Title level={5}>About</Typography.Title>
            <Typography.Link href="https://openguild.notion.site/Careers-at-OpenGuild-Finance-52106cfba0c94bd7b06eaf25607d24cc">
              Careers
            </Typography.Link>
            <Typography.Link href="https://openguild.gitbook.io/v1/faq">FAQ</Typography.Link>
            <Typography.Link href="https://openguild.gitbook.io/v1/legal/terms-and-conditions">Terms</Typography.Link>
            <Typography.Link href="https://openguild.finance/privacy-policy">Privacy Policy</Typography.Link>
          </Space>
        </Col>
        <Col span={8}>
          <Space direction="vertical">
            <Typography.Title level={5}>Docs</Typography.Title>
            <Typography.Link href="https://openguild.gitbook.io/v1/">Docs</Typography.Link>
          </Space>
        </Col>
        <Col span={8}>
          <Typography.Title level={5}>Community</Typography.Title>
          <Space direction="horizontal">
            <Typography.Link href="https://openguildfinance.medium.com/">
              <MediumOutlined height="2em" />
            </Typography.Link>
            <Typography.Link href="https://twitter.com/og_finance">
              <TwitterOutlined height="2em" />
            </Typography.Link>
            <Typography.Link href="https://discord.com/invite/aQ3yyCnwqf">
              <img
                src={`${process.env.PUBLIC_URL}/discord-icon.png`}
                style={{
                  height: "1.3em",
                  paddingBottom: "0.15em",
                  imageRendering: "crisp-edges",
                }}
              />
            </Typography.Link>
          </Space>
        </Col>
      </Row>
    </Space>
  );
};

export default Footer;
