import { BigNumber, utils } from "ethers";

export class PrecisionConverter {
  decimal = 0;
  constructor(inputDecimal: number) {
    this.decimal = inputDecimal;
  }

  fromContractAmount = (input: BigNumber): number => {
    return Number(utils.formatUnits(input, this.decimal));
  };
  toContractAmount = (input: string): BigNumber => {
    return utils.parseUnits(input, this.decimal);
  };
}
