import { Card, Row, Typography } from "antd";
import { roundDecimal } from "../helpers/utils";

const SharesCard = ({ shares, totalSupply }: { shares: number; totalSupply: number }): JSX.Element => {
  return (
    <Card size="small" style={{ backgroundColor: "#150428" }}>
      <Row style={{ paddingTop: "1vh" }}>
        <Typography.Text style={{ fontSize: "15px" }}>Shares Owned</Typography.Text>
      </Row>
      <Row style={{ paddingTop: "0.5vh" }}>
        <Typography.Text style={{ fontSize: "15px" }}>{shares} shares</Typography.Text>
      </Row>
      <Row style={{ paddingTop: "1vh" }}>
        <Typography.Text style={{ fontSize: "15px" }}>Total % Owned</Typography.Text>
      </Row>
      <Row style={{ paddingTop: "0.5vh" }}>
        <Typography.Text style={{ fontSize: "15px" }}>
          {!totalSupply ? 0 : roundDecimal(shares / totalSupply, 3) * 100} %
        </Typography.Text>
      </Row>
    </Card>
  );
};

export default SharesCard;
